@import "../../styles/_settings";

.dropzone__image,
.dropzone__instructions,
.dropzone__shim {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dropzone {
  position: relative;
  background: $grey-3;

  &::after {
    position: absolute;
    content: "Image not defined";
    color: $white;
    font-size: $font-xxlarge;
    line-height: 24px;
    top: calc(50% - 12px);
    text-align: center;
    width: 100%;
  }
}

.dropzone__image {
  z-index: 1;
}

.dropzone__instructions {
  z-index: 2;
  box-sizing: border-box;
  border: 4px solid $medium-grey;
  visibility: hidden;
  text-align: center;
  background: rgba(238, 238, 238, 0.8);
}
.dropzone__instructions--opened {
  visibility: visible;
}

.dropzone__instructions clr-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px auto auto -30px;
}

.dropzone__instructions div {
  position: absolute;
}

.dropzone__shim {
  z-index: 3;
  background: transparent;
  cursor: pointer;
}

.constrained {
  white-space: nowrap;
  overflow: hidden;
  max-width: 20em;
  text-overflow: ellipsis;
}

.c-videoPreviewWrapper {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid $white;
}
