//  No Spacing utilities
// --------------------
.u-no-padding {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.u-no-margin {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.u--no-spacing {
  padding: 0 !important;
  margin: 0 !important;
}

.u--no-margin {
  margin: 0;
}

.u--no-margin th {
  line-height: 24px;
}

.u-no-margin-left {
  margin-left: 0 !important;
  margin-right: auto;
}

.u-no-margin-right {
  margin-right: 0 !important;
}

.u-no-margin-top {
  margin-top: 0 !important;
}

.u-no-margin-bottom {
  margin-bottom: 0 !important;
}

.u-no-padding-left {
  padding-left: 0 !important;
}

.u-no-padding-right {
  padding-right: 0 !important;
}

.u-no-padding-top {
  padding-top: 0 !important;
}

.u-no-padding-bottom {
  padding-bottom: 0 !important;
}
