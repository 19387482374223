//  Typography utilities
// --------------------

//  1. Global
//  2. Typograhy utils

//  1. Global Typography
// --------------------

::-webkit-input-placeholder {
  direction: ltr;
  text-align: left;
}
:-moz-placeholder {
  direction: ltr;
  text-align: left;
}
::-moz-placeholder {
  direction: ltr;
  text-align: left;
}
:-ms-input-placeholder {
  direction: ltr;
  text-align: left;
}

section > h1 {
  margin-top: 0;
}

a:visited {
  color: $dark-blue;
}

h1 > i {
  margin-right: $margin-small;
}

dt {
  margin: 1rem 0 0 0;
  font-weight: 900;
}
ul {
  margin: 0 0 0 0;
}

.c-link {
  cursor: pointer;
  text-decoration: none;
  color: $blue;

  &:visited {
    color: $dark-blue;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &--submit {
    background: transparent;
    border-radius: 4px;
    border: 1px solid $medium-grey;
    color: $medium-grey;
    cursor: pointer;
    font-size: $font-xsmall;
    line-height: 11px;
    margin-right: $margin-xsmall;
    padding: 1px 3px;

    &:hover {
      border-color: $grey;
      color: $grey;
    }
  }

  &--float {
    float: left;
  }
}

.c-li {
  list-style-type: none;
}

//  2. Typograhy utils
// --------------------
.u-capitalize {
  text-transform: capitalize;
}

.u-current-item {
  font-weight: bold;
}

.u-h2-like {
  color: $black;
  font-size: 28px;
  letter-spacing: normal;
}

.u-txt-bold {
  font-weight: bold;
}

.u-txt-center {
  text-align: center;
}

.u-text--ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.u--ib {
  display: inline-block;
  text-align: center;
  font-style: italic;
}

.u-line-overflow {
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.u-text--nowrap {
  white-space: nowrap;
}

.u-txt-right {
  text-align: right;
}

.u-uppercase {
  text-transform: uppercase !important;
}

//@todo A rectifier :

.u--hint {
  color: $warm-grey;
}

.u--subtext {
  color: $super-light-grey;
  font-size: 0.9em;
}
