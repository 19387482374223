//  Sizes utilities
// --------------------
// @todo : refacto class name

.u-mw-50 {
  max-width: 50%;
}

.u-w100 {
  width: 100%;
}
.u-w100 .clr-input-wrapper {
  max-width: none;
}
.u-min-w100 {
  min-width: 100% !important;
  width: 100%;
}

.u-min-height-75vh {
  min-height: 75vh;
}

.u-min-height-100 {
  min-height: 6rem;
}

.u-m-w-150p {
  max-width: 150px !important;
}

.u-m-w-33 {
  max-width: 33.33% !important;
}
.u-w40p {
  width: 40px;
  max-width: 40px;
  min-width: 40px !important;
}
.u-w50p {
  width: 50px;
  max-width: 50px;
  min-width: 50px !important;
}

.u-w60p {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}

.u-w70p {
  width: 70px;
  max-width: 70px;
  min-width: 70px !important;
}

.u-w80p {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}

.u-w100p {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.u-w120p {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
}

.u-w135p {
  width: 135px;
  max-width: 135px;
  min-width: 135px;
}

.u-w150p {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.u-w160p {
  width: 160px;
  max-width: 160px;
  min-width: 160px;
}

.u-w170p {
  width: 170px;
  max-width: 170px;
  min-width: 170px;
}

.u-w200p {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.u-w250p {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}

.u-w350p {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}

.u-w450p {
  width: 450px;
  max-width: 450px;
  min-width: 450px;
}

.u-col-mw-150p {
  max-width: 150px !important;
}

.u-col-mw-380p {
  max-width: 380px !important;
}

.u-col-w60p {
  max-width: 60px !important;
}

.sidenav {
  z-index: 1;
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  box-shadow: 0 0 $unit-small $black;
  border-right: 0 !important;
}

@media screen and (max-width: 1250px) {
  .u-col-mw-150p {
    max-width: 150px;
  }
  .u-col-mw-380p {
    max-width: 150px;
  }

  .u-col-w60p {
    max-width: 60px;
  }
}

.alerts-pager {
  width: 190px;
}
