.image-editor {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  .edit-btn {
    margin-right: 6px;
    cursor: pointer;
    border: none;
  }

  // Modal styles
  .modal-title {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      gap: 1rem;
      padding: 5px;
      flex: 1;

      &__text,
      clr-icon {
        font-size: $font-xmedium;
        color: $white;
      }
    }

    .alerts {
      display: flex;
      flex-direction: column;
      flex: auto;
      width: min-content;
      margin-top: -16px;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-editor__body {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: $margin-small;
      color: $white;

      .image-editor-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .image-editor-container,
      .checked-image {
        object-fit: contain;
        width: 60vw;
        height: 60vh;
      }

      .crop-error {
        .cropper-view-box {
          outline-color: $red;
        }
        .cropper-line,
        .cropper-point {
          background-color: $red;
        }
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      &__action {
        align-items: end;
      }

      &__ratio {
        align-items: start;

        button {
          margin-left: 12px;
        }
      }
    }

    .decision-btn-container {
      display: flex;
      justify-content: center;
      margin-top: $margin-small;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.2rem;
  }

  .modal-dialog {
    width: 100vw;
    height: 100vh !important;
    max-height: 100vh !important;
  }

  .modal-header--accessible {
    background-color: $dark-grey-4;
  }

  .modal-content {
    background-color: $dark-grey-3 !important;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  // Cropper styles
  .cropper-point {
    width: 9px;
    height: 9px;

    &.point-nw,
    &.point-n,
    &.point-ne {
      top: -5px;
    }

    &.point-w,
    &.point-e {
      margin-top: -5px;
    }

    &.point-sw,
    &.point-s,
    &.point-se {
      bottom: -5px;
    }

    &.point-nw,
    &.point-sw,
    &.point-w {
      left: -5px;
    }

    &.point-ne,
    &.point-se,
    &.point-e {
      right: -5px;
    }

    &.point-n,
    &.point-s {
      margin-left: -5px;
    }
  }

  // Button styles
  .action-btn {
    background-color: $dark-grey-4;
    color: $white;
    border: none;
    outline: none;

    clr-icon {
      color: $white;
      width: 18px;
      height: 18px;

      &.clr-icon__scale {
        transform: scale(-1, 1);
      }
    }

    img {
      &.img__rotate {
        transform: rotate(90deg);
      }
    }

    &:hover,
    &__active {
      background-color: #737373;
    }
  }
}
