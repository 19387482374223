//  Units Settings
// --------------------

//  1. Unit
// --------------------
$global-base-unit: 6px;
$base-unit: 8px;
$unit-xsmall: $base-unit;
$unit-small: $base-unit * 2;
$unit-std: $base-unit * 4;
$unit-medium: $base-unit * 6;
$unit-big: $base-unit * 8;
