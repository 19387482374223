//  Africanews Dislay
// --------------------
.t-africanews {
  .show-for-africanews {
    display: block;
  }

  .show-for-euronews {
    display: none;
  }

  .hide-for-africanews {
    display: none !important;
  }
}

.u-background--africanews {
  background: $white;
}
