//  Tools spacings
// --------------------

//  1. Generate Padding
//  2. Generate Padding Start
//  3. Generate Padding End
//  4. Generate Padding Y
//  5. Generate Padding X
//  6. Generate Margin
//  7. Generate Margin Start
//  8. Generate Margin End
//  9. Generate Margin Y
// 10. Generate Margin X

// We use the breakpoint to create a part of the class name e.g. `.u-padding-medium-only-3`.
// Instead of adding functions which convert the breakpoint to a string and then doing
// some string replacement, we simply create a nested list where we add the string to
// be inserted into the class name.
$breakpointList: (
  (small only, "small-only"),
  (medium down, "medium-down"),
  (medium, "medium"),
  (medium only, "medium-only"),
  (large, "large"),
  (large only, "large-only"),
  (xlarge, "xlarge")
);

//  1. Generate Padding
// --------------------
@mixin generate-padding($multiplier, $side: null) {
  @if $side {
    .u-padding-#{$side}-#{$multiplier} {
      padding-#{$side}: $multiplier * $global-base-unit !important;
    }

    @each $breakpoint, $breakpointClass in $breakpointList {
      .u-padding-#{$side}-#{$breakpointClass}-#{$multiplier} {
        @include breakpoint($breakpoint) {
          padding-#{$side}: $multiplier * $global-base-unit !important;
        }
      }
    }
  } @else {
    .u-padding-#{$multiplier} {
      padding: $multiplier * $global-base-unit !important;
    }

    @each $breakpoint, $breakpointClass in $breakpointList {
      .u-padding-#{$breakpointClass}-#{$multiplier} {
        @include breakpoint($breakpoint) {
          padding: $multiplier * $global-base-unit !important;
        }
      }
    }
  }
}

//  2. Generate Padding Start
// --------------------
// Used to manage LTR and RTL in one class
@mixin generate-padding-start($multiplier) {
  .u-padding-start-#{$multiplier} {
    padding-left: $multiplier * $global-base-unit !important;

    @include direction-rtl() {
      padding-left: 0;
      padding-right: $multiplier * $global-base-unit !important;
    }
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-padding-start-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        padding-left: $multiplier * $global-base-unit !important;

        @include direction-rtl() {
          padding-left: 0;
          padding-right: $multiplier * $global-base-unit !important;
        }
      }
    }
  }
}

//  3. Generate Padding End
// --------------------
// Used to manage LTR and RTL in one class
@mixin generate-padding-end($multiplier) {
  .u-padding-end-#{$multiplier} {
    padding-right: $multiplier * $global-base-unit !important;

    @include direction-rtl() {
      padding-left: $multiplier * $global-base-unit !important;
      padding-right: 0;
    }
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-padding-end-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        padding-right: $multiplier * $global-base-unit !important;

        @include direction-rtl() {
          padding-left: $multiplier * $global-base-unit !important;
          padding-right: 0;
        }
      }
    }
  }
}

//  4. Generate Padding Y
// --------------------
// Used to manage top and bottom padding in one class
@mixin generate-padding-y($multiplier) {
  .u-padding-y-#{$multiplier} {
    padding-bottom: $multiplier * $global-base-unit !important;
    padding-top: $multiplier * $global-base-unit !important;
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-padding-y-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        padding-bottom: $multiplier * $global-base-unit !important;
        padding-top: $multiplier * $global-base-unit !important;
      }
    }
  }
}

//  5. Generate Padding X
// --------------------
// Used to manage left and right padding in one class
@mixin generate-padding-x($multiplier) {
  .u-padding-x-#{$multiplier} {
    padding-left: $multiplier * $global-base-unit !important;
    padding-right: $multiplier * $global-base-unit !important;
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-padding-x-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        padding-left: $multiplier * $global-base-unit !important;
        padding-right: $multiplier * $global-base-unit !important;
      }
    }
  }
}

//  6. Generate Margin
// --------------------
@mixin generate-margin($multiplier, $side: null) {
  @if $side {
    .u-margin-#{$side}-#{$multiplier} {
      margin-#{$side}: $multiplier * $global-base-unit !important;
    }

    @each $breakpoint, $breakpointClass in $breakpointList {
      .u-margin-#{$side}-#{$breakpointClass}-#{$multiplier} {
        @include breakpoint($breakpoint) {
          margin-#{$side}: $multiplier * $global-base-unit !important;
        }
      }
    }
  } @else {
    .u-margin-#{$multiplier} {
      margin: $multiplier * $global-base-unit !important;
    }

    @each $breakpoint, $breakpointClass in $breakpointList {
      .u-margin-#{$breakpointClass}-#{$multiplier} {
        @include breakpoint($breakpoint) {
          margin: $multiplier * $global-base-unit !important;
        }
      }
    }
  }
}

//  7. Generate Margin Start
// --------------------
// Used to manage LTR and RTL in one class
@mixin generate-margin-start($multiplier) {
  .u-margin-start-#{$multiplier} {
    margin-left: $multiplier * $global-base-unit !important;

    @include direction-rtl() {
      margin-left: 0;
      margin-right: $multiplier * $global-base-unit !important;
    }
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-margin-start-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        margin-left: $multiplier * $global-base-unit !important;

        @include direction-rtl() {
          margin-left: 0;
          margin-right: $multiplier * $global-base-unit !important;
        }
      }
    }
  }
}

//  8. Generate Margin End
// --------------------
// Used to manage LTR and RTL in one class
@mixin generate-margin-end($multiplier) {
  .u-margin-end-#{$multiplier} {
    margin-right: $multiplier * $global-base-unit !important;

    @include direction-rtl() {
      margin-left: $multiplier * $global-base-unit !important;
      margin-right: 0;
    }
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-margin-end-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        margin-right: $multiplier * $global-base-unit !important;

        @include direction-rtl() {
          margin-left: $multiplier * $global-base-unit !important;
          margin-right: 0;
        }
      }
    }
  }
}

//  9. Generate Margin Y
// --------------------
// Used to manage top and bottom margin in one class
@mixin generate-margin-y($multiplier) {
  .u-margin-y-#{$multiplier} {
    margin-bottom: $multiplier * $global-base-unit !important;
    margin-top: $multiplier * $global-base-unit !important;
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-margin-y-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        margin-bottom: $multiplier * $global-base-unit !important;
        margin-top: $multiplier * $global-base-unit !important;
      }
    }
  }
}

//  10. Generate Margin X
// --------------------
// Used to manage left and right margin in one class
@mixin generate-margin-x($multiplier) {
  .u-margin-x-#{$multiplier} {
    margin-left: $multiplier * $global-base-unit !important;
    margin-right: $multiplier * $global-base-unit !important;
  }

  @each $breakpoint, $breakpointClass in $breakpointList {
    .u-margin-x-#{$breakpointClass}-#{$multiplier} {
      @include breakpoint($breakpoint) {
        margin-left: $multiplier * $global-base-unit !important;
        margin-right: $multiplier * $global-base-unit !important;
      }
    }
  }
}
