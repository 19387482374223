//  Status
// --------------------
a.storyStatus,
.storyStatus {
  position: static;
  color: $blue;
  padding: 2px;

  .langageStatus & {
    padding: 2px;
    margin: 2px 2px 0 0;
    border: 1px solid transparent;
    display: inline-block;
  }

  &--online {
    color: $green;

    &:visited,
    &:hover {
      color: $green;
    }
  }

  &--scheduled {
    color: $orange-hot;

    &:visited,
    &:hover {
      color: $orange-hot;
    }
  }

  &--to_republish {
    color: $orange;

    &:visited,
    &:hover {
      color: $orange;
    }
  }

  &--offline {
    color: $red;

    &:visited,
    &:hover {
      color: $red;
    }
  }

  &--edit {
    color: $medium-blue;

    &:visited,
    &:hover {
      color: $medium-blue;
    }
  }

  &--new {
    color: $blue;

    &:visited,
    &:hover {
      color: $blue;
    }
  }

  &--ready_to_publish {
    color: $color--ready_to_publish;

    &:visited,
    &:hover {
      color: $color--ready_to_publish;
    }
  }

  &--in_translation {
    color: $color--in_translation;

    &:visited,
    &:hover {
      color: $color--in_translation;
    }
  }

  &--translated {
    color: $color--translated;

    &:visited,
    &:hover {
      color: $color--translated;
    }
  }

  &--video-ok {
    border: 1px solid currentColor !important;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
  }

  &--selected {
    text-decoration: underline;
  }
}

.videoStatus.stories-status__tooltip-video {
  &.storyStatus--video-no {
    color: $red !important;
    border: none !important;
    text-decoration: none !important;

    &::before {
      content: "no ";
      display: inline;
      font-weight: bold;
    }
  }

  &.storyStatus--video-ok {
    color: $green !important;
    border: 1px solid $grey !important;
    text-decoration: none !important;

    &::before {
      content: "";
      display: inline;
    }
  }
}

.eventLangage {
  padding: 2px 0;
}
