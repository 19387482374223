//  Global theme
// --------------------
body {
  background: $super-light-grey-2;
  overflow: hidden;
}

.c-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-content {
  margin-top: $margin-std;
}

.c-icon-title {
  margin-right: 12px;
}

.main-container .content-container .sidenav {
  overflow: visible;
  z-index: 502; // z-index of clr-dg-action-overflow is 500 and was over the z-index of the sidenav, shifted to 502 to allow tooltips to stay above the header of the translation's datagrid.
}

.completer-dropdown {
  max-height: 22em;
  overflow-y: scroll;
  overflow-x: hidden;
}

.clr-wizard .modal-content {
  align-items: inherit;
}

.datagrid {
  .datagrid-row {
    border-top: none;
  }

  .datagrid-table .datagrid-cell.datagrid-row-actions,
  .datagrid-row-scrollable {
    border-top: 0.05rem solid $light-grey;
  }

  .datagrid-row-flex,
  .datagrid-row-scrollable {
    background-color: inherit;
  }

  .datagrid-table {
    .datagrid-cell:focus,
    .datagrid-column:focus {
      outline: none;
    }
  }

  .modal-body & {
    margin-top: 0;
  }

  .no-header & {
    .datagrid-header {
      display: none;
    }
  }

  .datagrid-detail-open & {
    .datagrid-row-scrollable {
      overflow: hidden;
    }
  }

  .datagrid-row-detail-open {
    .datagrid-row-sticky,
    .datagrid-row-scrollable {
      background-color: $info-subcolor;
    }
  }
}

.datagrid-outer-wrapper {
  flex-grow: unset !important;
}

ul.list-unstyled:not([cds-list]) {
  list-style: none;
}
