//  Colors
// --------------------

//  1. Messages Colors
//  2. Background Colors
//  3. Events Status and Events Languages Colors
//  4. Utils colors

//  1. Colors
// --------------------
.black,
.is-std {
  color: $black;
}

.grey {
  color: $grey;
}

.green,
.is-success {
  color: $green;
}

.blue,
.is-info {
  color: $blue;
}

.dark-blue {
  color: $dark-blue;
}

.red,
.is-error {
  color: $red;
}

.jaune-africa {
  color: $jaune-africa;
}

.jaune-fonce-africa {
  color: $jaune-fonce-africa;
}

.orange,
.is-warning {
  color: $orange;
}

.orange-hot,
.is-warning-hot {
  color: $orange-hot;
}

//  2. Background Colors
// --------------------
.bg-grey {
  background-color: $super-light-grey-2;
}

//  3. Events Status and Events Languages Colors
// --------------------

$publication-statuses: (
  "new": $color--new,
  "edit": $color--edit,
  "scheduled": $color--scheduled,
  "to_republish": $color--to_republish,
  "online": $color--online,
  "offline": $color--offline,
  "in_translation": $color--in_translation,
  "in_duplication": $color--in_duplication,
  "duplicated": $color--duplicated,
  "awaiting": $color--awaiting,
  "ready_to_publish": $color--ready_to_publish,
  "submitted": $color--submitted,
  "approved": $color--approved,
);

$pyramid-statuses: (
  "new": $color--new,
  "pth": $color--pth,
  "das": $color--das,
  "wip": $color--wip,
  "psb": $color--psb,
  "pap": $color--pap,
  "loc": $color--loc,
  "aal": $color--aal,
  "pal": $color--pal,
  "cls": $color--cls,
);

@mixin generate-status-classes($statuses, $classname) {
  @each $status, $color in $statuses {
    #{$classname}#{$status},
    #{$classname}#{$status} a {
      color: #{$color};
    }
  }
}

.eventStatus,
.eventLangage {
  font-weight: bold;

  clr-icon {
    margin-right: 6px;
  }

  @include generate-status-classes($publication-statuses, "&--");
  @include generate-status-classes($pyramid-statuses, "&--");
}
//  4. Utils colors
// --------------------

.u-font-grey {
  color: $grey !important;
}

$color--pth1: $red;
