// Ng-completer Override
// --------------

//  1. Ng-completer Style
// --------------
ng2-completer {
  display: block;
  border-bottom: 0 none !important;
  padding: 0 !important;
  background-image: url("../../assets/images/svg/icon-search.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 0 !important;
  background-size: auto !important;
  transition: none !important;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    margin: 0;
    padding: 0 0.3rem;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: 0 0;
    height: 1.2rem;
    color: $black;
    color: var(--clr-forms-text-color, $black);
    border-bottom: 0.05rem solid;
    border-bottom-color: #b3b3b3;
    border-bottom-color: var(--clr-forms-border-color, #b3b3b3);
    display: inline-block;
    padding: 0 0.3rem;
    max-height: 1.2rem;
    font-size: 0.65rem;
    outline: 0 none;
    text-indent: 0.8rem;
  }

  input:not([readonly]) {
    background: linear-gradient(to bottom, transparent 95%, var(--clr-forms-focused-color, #0072a3) 95%) no-repeat;
    background-size: 0 100%;
    transition: background-size 0.2s ease;
  }

  input:not([readonly]):focus {
    border-bottom-color: #0072a3;
    border-bottom-color: var(--clr-forms-focused-color, #0072a3);
    background-size: 100% 100%;
  }
}

ng2-completer input {
  width: 100%;
}

ng2-completer.tag-search input {
  min-width: auto;
}
