// Tags
// --------------

.tag-container {
  .tag-selected {
    margin-bottom: 0;
    margin-top: 0;
  }

  .tag-selected.label {
    background: $blue;
    border: 1px solid $blue;
    color: $white;

    .badge {
      background: transparent;
    }
  }

  .u-colortheme--euronews {
    .tag-selected.label {
      color: $white;
    }
  }

  &.label:hover {
    background: $blue;
    cursor: pointer;
  }

  &-group {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    padding-left: $padding-std;
    width: 100%;
  }

  &-group__primary .tag-selected.label {
    background: $medium-blue;
    border: 1px solid $medium-blue;
    color: $white;
  }

  &-group__primary .tag-selection:first-of-type .tag-selected.label {
    background: $green;
    border: 1px solid $green;
    color: $white;
  }

  &-group__primary .tag-selection:first-of-type .tag-selected.label.label-orange {
    background: $orange-hot;
    border: 1px solid $orange-hot;
    color: $white;
  }

  &-group__primary .tag-selected.label.label-orange {
    background: $orange-hot;
    border: 1px solid $orange-hot;
    color: $white;
  }

  &-group__secondary .tag-selected.label {
    background: $cyan-blue;
    border: 1px solid $cyan-blue;
  }
}

.suggested-tags {
  padding: 0;

  .badge {
    display: none;
    margin: 0 -9px 0 6px;
  }

  .tag-selected.label:hover {
    background: $super-light-grey;
  }
}

.u-colortheme {
  &--euronews {
    .tag-selected.label {
      border-color: $blue;
      border-radius: 10px;
      color: $blue;
    }
  }
}

// Locked form (metadata replication)
.locked {
  .checkbox input[type="checkbox"]:checked + label::before {
    background: $super-light-grey-2;
  }

  .checkbox input[type="checkbox"] + label::before {
    background: $super-light-grey-2;
    border: 1px solid $light-grey;
  }

  .checkbox label,
  .checkbox-inline label {
    color: $light-grey;
    cursor: not-allowed;
  }

  //Disabled tags dragula
  #listEuronewsThemesSuggest,
  #listLivingThemesSuggest,
  .tag-container.theme-container .tag-selection,
  .tag-selection,
  .themes-selection-container .selectedThemes .select-theme.drag-theme {
    cursor: not-allowed;

    .tag-selected {
      cursor: not-allowed;

      .badge {
        display: none;
      }

      &.label,
      &.label.default-theme {
        background: $super-light-grey-2;
        border: 1px solid $light-grey;
        color: $grey-3;

        &:hover {
          background: $super-light-grey-2;
        }

        span {
          cursor: not-allowed;
        }
      }
    }
  }
}

.c-image_container {
  display: flex;
  width: 100%;

  .clr-control-container {
    width: 100%;
  }

  &.clr-form-control {
    flex-direction: row-reverse;

    .clr-control-label {
      align-self: center;
      height: 24px;
      margin: auto 0;
      max-width: 24px;
      min-width: 24px;
    }
  }

  .clr-input-wrapper {
    display: flex;
    max-height: none;
    width: 100%;

    .search-input {
      max-width: 30%;
    }

    cms-form-validator {
      flex: 1 0 70%;
      height: auto;
      max-height: none;
      max-width: 70%;
      white-space: pre;
    }
  }

  &_v2 {
    ng2-completer {
      margin-right: 0;
    }

    .clr-input-wrapper {
      display: flex;
    }
  }
}

.tag-container.theme-container cms-draggable-badge .tag-selected.label.default-theme,
.themes-selection-container .selectedThemes .drag-theme .tag-selected.label.default-theme {
  background: $green !important;
  border: 1px solid $green !important;
  color: $white !important;

  .badge {
    color: $white;
  }
}

.locked .themes-selection-container .selectedThemes .select-theme.drag-theme,
.locked.tag-container.theme-container .tag-selection {
  .tag-selected.label {
    background: $super-light-grey-2;
    border: 1px solid $light-grey;
    color: $grey !important;
    padding-right: 0.5rem;

    span {
      cursor: not-allowed;
    }
  }

  &:first-of-type .tag-selected.label {
    border: 1px solid $green;
    color: $green;
  }
}

.locked.tag-container .tag-selection {
  cursor: not-allowed;

  .tag-selected.label {
    background: $super-light-grey-2;
    border: 1px solid $light-grey;
    color: $grey;
    padding-right: 0.5rem;

    span {
      cursor: not-allowed;
    }
  }

  &:first-of-type .tag-selected.label {
    border: 1px solid $green;
    color: $green;
  }
}
