//  Margins Settings
// --------------------

//  1. Base margin
// --------------------
$margin-xxsmall: $base-unit * 0.5;
$margin-xsmall: $base-unit;
$margin-small: $base-unit * 2;
$margin-std: $base-unit * 4;
$margin-medium: $base-unit * 6;
$margin-large: $base-unit * 8;
