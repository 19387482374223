@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic-ext.woff2) format("woff2");
  unicode-range: u+0460-052f, u+1c80-1c88, u+20b4, u+2de0-2dff, u+a640-a69f, u+fe2e-fe2f;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic.woff2) format("woff2");
  unicode-range: u+0400-045f, u+0490-0491, u+04b0-04b1, u+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek-ext.woff2) format("woff2");
  unicode-range: u+1f??;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek.woff2) format("woff2");
  unicode-range: u+0370-03ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-vietnamese.woff2) format("woff2");
  unicode-range: u+0102-0103, u+0110-0111, u+0128-0129, u+0168-0169, u+01a0-01a1, u+01af-01b0, u+1ea0-1ef9, u+20ab;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin-ext.woff2) format("woff2");
  unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin.woff2) format("woff2");
  unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122,
    u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}
@font-face {
  font-display: swap;
  font-family: Arial size-adjust;
  font-style: normal;
  font-weight: 400;
  size-adjust: 109%;
  src: local("Arial");
}
