//  Overflow
// --------------------
.u-text-overflow {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.u-no-scroll {
  overflow: hidden;
}
