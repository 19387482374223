@import "../settings";

.ProseMirror {
  // Reset ProseMirror default styles
  word-wrap: unset;
  white-space: unset;
  font-variant-ligatures: unset;
  font-feature-settings: unset;

  font-size: $font-smedium;
  color: $dark-grey;

  &:focus {
    outline: none;
  }

  >,
  &:not(.default-styles) & {
    *,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-inter;
    }

    h2,
    h3,
    h4 {
      margin: 0;
    }

    h2,
    h3 {
      padding: 1rem 0;
    }

    h2 {
      font-size: $font-xmedium;
      line-height: 24px;
      font-weight: 900;
      padding: 1rem 0;
      clear: both;
      width: 100%;
    }

    h3 {
      font-size: $font-medium;
      line-height: 24px;
      font-weight: 800;
    }

    h4 {
      font-weight: 700;
      line-height: 1.3;
    }

    ol ol,
    ul ul {
      margin-left: 0;
      list-style: circle;
      clear: both;
    }

    ol,
    ul {
      margin: 1rem 0;
      padding: 0;
      font-size: $font-smedium;
      clear: both;

      li {
        margin-bottom: 12px;

        h2,
        h3,
        h4 {
          display: inline-block;
          width: auto;
        }
      }
    }

    p:not([cds-text]) {
      font-size: $font-smedium;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: $dark-grey;
      margin-top: 0;
      margin-bottom: 16px;
      word-wrap: normal;
      overflow-wrap: normal;

      // Placeholder
      &.is-editor-empty:first-child::before {
        color: $medium-grey;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
        font-style: italic;

        [dir="rtl"] & {
          float: right;
        }
      }
    }

    * a {
      color: $blue-neon-flashy;
      transition: color 0.2s ease-in-out;
      position: relative;

      &:hover {
        color: $blue-neon-flashy-light;
      }

      &:visited {
        color: $blue-neon-flashy;
      }

      &[tooltip]:hover {
        &::after,
        &::before {
          z-index: z("upside") + 1;
          position: absolute;
          left: 50%;
          display: block;
          background: $dark-grey-2;
        }

        &::after {
          content: attr(tooltip);
          top: calc(100% + 10px);
          width: max-content;
          max-width: 280px;
          padding: 3px 8px;
          border-radius: 5px;
          color: $white;
          font-size: $font-smedium;
          font-weight: 400;
          line-height: 1.3;
          text-align: center;
          word-wrap: break-word;
          word-break: break-all;
          filter: drop-shadow(0 1px 3px rgba($black, 0.4));
          transform: translateX(-50%);
        }

        &::before {
          content: "";
          top: 100%;
          width: 10px;
          height: 10px;
          transform: translate(-50%, 50%) rotate(45deg);
        }
      }
    }
  }

  //#region | Widgets
  // ----------------
  .widget-new {
    display: block;
    margin: 0 auto 1.2em;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-color: $white;
    z-index: 1;
    min-height: 1em;
    transition: background-color 1s ease-in;

    &:hover {
      background: $light-grey;
    }

    & *::selection,
    &::selection {
      background: transparent;
      color: inherit;
    }

    &__overlay {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0);
      transition: background 0.25s ease-in-out, border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: grab;
    }

    &__error-background {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__contents {
      // Keep tweets etc centered
      text-align: center;
    }

    &__contents > iframe {
      margin: 0 auto !important;
    }

    &__name {
      position: absolute;
      top: 3px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.2em;
      padding: 0.2em;
      background: rgba($white, 0.7);
      color: $dark-grey;
      font-size: $font-msmall;
      line-height: 1;
      white-space: nowrap;
    }

    &__figure {
      height: 100%;
      margin: 0;
      padding: 0;
      width: 100%;

      & + br {
        display: none;
      }
    }

    &__caption {
      display: block;
      margin: 0 0 $margin-xsmall;
      padding: 0;
      // Centers captions that can sit on one line
      text-align: center;
      width: 100%;
    }

    &__captionWrap {
      // Left aligns text that sits on more than one line
      color: $grey;
      display: inline-block;
      font-size: $font-small;
      line-height: 1.4;
      padding-top: 0.4em;
      text-align: left;

      a {
        color: $grey;
      }
    }

    &__captionCredit:empty,
    &__captionText:empty,
    &__captionWrap:empty {
      display: none;
    }

    [dir="rtl"] &__captionWrap {
      text-align: center;
    }
  }

  &-focused .has-focus .widget-new {
    box-shadow: 0 4px 10px rgba($black, 0.2);

    &__overlay {
      border: 1px solid black;
      background-color: rgba($black, 0.2);
    }
  }
  //#endregion

  //#region | Widgets Align
  // ----------------------
  .widget-new--align {
    // Z-index required to sit above sidebar background in CMS
    &-outdent-left,
    &-outdent-right {
      z-index: calc(z("default") * 2);
    }

    &-outdent-left::before,
    &-outdent-right::before {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      bottom: 0;
      width: 97px;
      background: rgba(0, 0, 0, 0.2);
      content: "\21A9";
      color: $white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      font-size: $font-xxlarge;
      font-weight: normal;
      text-align: center;
      padding-top: 0.5em;
    }

    &-outdent-right::before {
      left: auto;
      right: 0;
      content: "\21AA";
    }

    &-left,
    &-outdent-left {
      float: left;
      margin-left: 0;
      margin-right: 1em;
    }

    &-outdent-left {
      margin-left: -15%;
    }

    &-outdent-right,
    &-right {
      float: right;
      margin-left: 1em;
      margin-right: 0;
    }

    &-outdent-right {
      margin-right: -15%;
    }

    &-center,
    p + &-outdent-left,
    p + &-outdent-right {
      clear: both;
    }

    &-center {
      float: none;
    }
  }
  //#endregion

  //#region | Widgets Sizes
  // ----------------------
  .widget-new--size {
    &-xsmall {
      width: 25%;
    }

    &-small {
      width: 33.333333%;
    }

    &-medium {
      width: 50%;
    }

    &-large {
      width: 66.666666%;
    }

    &-xlarge {
      width: 75%;
    }

    &-fullwidth {
      width: 100%;
      clear: both;
    }

    &-fullscreen,
    &-extended {
      width: 130% !important;
      float: none !important;
      clear: both !important;
      margin-left: -15% !important;
      margin-right: -15% !important;

      // Required or the RHS gets cut-off
      z-index: z("default");
    }

    &-fullscreen::after,
    &-fullscreen::before,
    &-extended::after,
    &-extended::before {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      bottom: 0;
      width: 97px;
      background: rgba(0, 0, 0, 0.2);
      content: "\21A9";
      color: $white;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
      font-size: $font-xxlarge;
      font-weight: lighter;
      text-align: center;
      padding-top: 0.5em;
    }

    &-fullscreen::before,
    &-extended::before {
      left: auto;
      right: 0;
      content: "\21AA";
    }

    &-extended::before {
      content: "\21E4";
    }

    &-extended::after {
      content: "\21E5";
    }
  }
  //#endregion

  //#region | Infinite scroll cut off
  // --------------------------------
  .widget-new--infinite-scroll {
    position: relative;
  }
  .widget-new--infinite-scroll::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image:
      /* Bottom jagged */ linear-gradient(
        45deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(
        -45deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      /* Bottom fade  */ linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    background-position:
      /* Bottom jagged */ bottom center, bottom center, /* Bottom fade */ bottom center;
    background-size:
      /* Bottom jagged */ 10px 10px, 10px 10px, /* Bottom fade */ 100% 16px;
    background-repeat: repeat-x;
  }
  //#endregion

  //#region | Widgets Responsive
  // ---------------------------
  .breakpoint--medium,
  .breakpoint--small {
    .widget-new {
      &--size {
        &-xsmall,
        &-small,
        &-medium,
        &-large,
        &-xlarge,
        &-fullsize,
        &-extended,
        &-fullscreen {
          width: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      &--align {
        &-outdent-left,
        &-left,
        &-center,
        &-right,
        &-outdent-right {
          float: none !important;
          clear: both !important;
          margin: 0 auto 1.2em auto !important;
        }
      }

      &--align-outdent-left,
      &--align-outdent-right,
      &--size-fullscreen {
        ::after,
        ::before {
          display: none;
        }
      }
    }
  }
  //#endregion

  //#region | Widgets Fixes
  // ----------------------
  .widget-new--type {
    // Widget Tweet
    &-tweet {
      min-width: 250px;
      max-width: 500px;

      .twitter-tweet {
        margin: 0 auto !important;
      }
    }

    // Widget Freeform
    &-freeform {
      .widget-new__contents {
        text-align: left;
      }

      .widget-new__contents > * {
        max-width: 100% !important;
      }

      iframe {
        max-width: 100% !important;
        position: relative;
      }
    }

    // Widget Quotation
    &-quotation_v2 {
      .quote {
        &::before {
          [dir="rtl"] & {
            left: auto;
            right: 0;
          }
        }

        &::after {
          [dir="rtl"] & {
            transform: scaleX(-1);
          }
        }
      }
    }

    // Widget Related
    &-related {
      ul:not(:empty)::before {
        content: "Related";

        [lang="fr"] & {
          content: "Lire aussi";
        }

        [lang="de"] & {
          content: "Lesen Sie auch";
        }

        [lang="it"] & {
          content: "Per appronfondire";
        }

        [lang="es"] & {
          content: "Leer";
        }

        [lang="pt"] & {
          content: "Notícias relacionadas";
        }

        [lang="tr"] & {
          content: "İLGİLİ HABERLER";
        }

        [lang="ru"] & {
          content: "Читать также";
        }

        [lang="ar"] & {
          content: "موضوعات متعلقة";
        }

        [lang="fa"] & {
          content: "بیشتر بخوانید";
        }

        [lang="el"] & {
          content: "Διαβάστε επίσης";
        }

        [lang="hu"] & {
          content: "Kapcsolódó cikkünk";
        }
      }

      [dir="rtl"] & {
        ul:not(:empty) {
          margin-left: 0 !important;
          margin-right: 24px !important;
          text-align: right;

          &::after {
            right: 0;
          }
        }

        li:not(:empty) {
          margin-left: 16px;
          margin-right: 0;

          &::after {
            margin-left: 0;
            margin-right: 20px;
            transform: rotate(180deg) scale(1.2);
          }
        }
      }
    }
  }
  //#endregion

  //#region | Extensions Fixes
  // -------------------------

  // Comments
  c {
    text-decoration: underline;
    text-decoration-thickness: 0;
    text-decoration-color: transparent;
    transition: text-decoration 0.2s, background-position 0.1s;
  }

  .breakpoint--xlarge &.display-comments {
    c {
      text-decoration-thickness: 2px;
      text-decoration-color: $orange;
      background: linear-gradient(transparent 50%, rgba($orange, 0.3) 50%);
      background-size: 100% 200%;

      &.active {
        background-position: 100% 100%;
      }
    }
  }

  :not(.breakpoint--xlarge) & cms-wysiwyg-comments-list {
    display: none;
  }

  .breakpoint--xlarge & cms-wysiwyg-comments-list {
    display: inline;
  }

  cms-wysiwyg-comments-list {
    .comments-container {
      .comments-list {
        > div {
          display: flex;
          flex-direction: column;
        }
      }
    }

    [dir="rtl"] & {
      .comments-container {
        .comments-list-toggle,
        .comments-list {
          transform: translateX(calc(-100% - 20px));
        }

        .comments-list-toggle {
          left: 0;
          right: unset;
        }

        .comments-list {
          mask-position: 100% -30px;
        }

        &.display-comments {
          .comments-list-toggle {
            transform: translateX(calc(-100% - 20px - 300px));
          }

          .comments-list {
            &.fade-top {
              mask-position: 100% 0;
            }

            &.fade-bottom {
              mask-position: 100% -30px;
            }

            &.fade-top.fade-bottom {
              mask-size: 100% 100%;
              mask-position: 100% 0;
            }
          }
        }
      }
    }
  }

  cms-wysiwyg-comments-thread {
    [dir="rtl"] & {
      .delete-thread {
        left: 2px;
        right: unset;
      }

      .comment-thread {
        .new-comment {
          .new-comment-input::placeholder {
            text-align: right;
          }
        }
      }
    }
  }

  cms-wysiwyg-comment {
    [dir="rtl"] & {
      .delete-comment {
        left: 0;
        right: unset;
      }
    }
  }
}
//#endregion

.prosemirror-dropcursor-block {
  background-color: transparent !important;
  border-top: 3px dotted $blue-neon;
}

// Tiptap floating menus
[id^="tippy-"] {
  z-index: z("upside") !important;

  .tippy-box {
    filter: drop-shadow(0 1px 3px rgba($black, 0.4));
    max-width: 650px !important;

    &::after,
    &::before {
      content: "";
      display: none;
      position: absolute;
      z-index: -1;
      width: 10px;
      height: 10px;
      background-color: $dark-grey-2;
    }

    &[data-placement="top"] {
      &::after {
        display: block;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &[data-placement="bottom"] {
      &::after {
        display: block;
        left: 50%;
        bottom: 100%;
        transform: translate(-50%, 50%) rotate(45deg);
      }
    }

    &[data-placement="left"] {
      &::after {
        display: block;
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &[data-placement="right"] {
      &::after {
        display: block;
        right: 100%;
        top: 50%;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }

    .tippy-content {
      .floating-menu,
      .bubble-menu {
        border-radius: 5px;
        display: flex;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background: $dark-grey-2;
          color: $white;
          cursor: pointer;
          width: 50px;
          height: 50px;
          outline: none;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:not(:last-child) {
            border-right: 1px solid rgba($white, 0.1);
          }

          &.active,
          &:hover {
            color: $blue-neon-flashy-light;
          }

          &.active {
            background: $dark-grey;
            border-right-color: rgba($white, 0.17);
          }

          &[disabled] {
            cursor: not-allowed;
          }

          .text-icon {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.tippy-arrow {
  transform-style: preserve-3d;

  &::after {
    content: "";
    position: absolute;
    left: -8px;
    transform: translateZ(-1px);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}
