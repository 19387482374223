@charset "UTF-8";
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic-ext.woff2) format("woff2");
  unicode-range: u+0460-052f, u+1c80-1c88, u+20b4, u+2de0-2dff, u+a640-a69f, u+fe2e-fe2f;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic.woff2) format("woff2");
  unicode-range: u+0400-045f, u+0490-0491, u+04b0-04b1, u+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek-ext.woff2) format("woff2");
  unicode-range: u+1f??;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek.woff2) format("woff2");
  unicode-range: u+0370-03ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-vietnamese.woff2) format("woff2");
  unicode-range: u+0102-0103, u+0110-0111, u+0128-0129, u+0168-0169, u+01a0-01a1, u+01af-01b0, u+1ea0-1ef9, u+20ab;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin-ext.woff2) format("woff2");
  unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin.woff2) format("woff2");
  unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}
@font-face {
  font-display: swap;
  font-family: Arial size-adjust;
  font-style: normal;
  font-weight: 400;
  size-adjust: 109%;
  src: local("Arial");
}
.datagrid-filter .c-custom-filter__header {
  margin-bottom: 20px;
  font-weight: bold;
}
.datagrid-filter .c-custom-filter__header-title {
  font-size: 12px;
  padding-top: 2px;
}
.datagrid-filter .c-custom-filter__footer {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #cccccc;
  text-align: right;
}
.datagrid-filter .c-custom-filter__footer .btn {
  margin: 0;
}
.datagrid-filter .c-custom-filter__footer .btn-outline {
  margin-right: 12px;
}
.datagrid-filter .datagrid-filter-close-wrapper {
  display: none;
}

label span.required::after {
  content: "*";
  font-size: 1.1em;
  color: #c92100;
  margin: 0 0 0 0.2em;
}

label span.required {
  margin-top: 0;
  margin-bottom: 0;
  top: 0;
}

.locked .checkbox input[type=checkbox]:checked + label::before {
  background: #aaaaaa !important;
}

input:-webkit-autofill, input:-webkit-autofill:focus, input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/******************************/
/* Test for news Clarity form */
/******************************/
.clr-control-label {
  font-weight: 400;
  line-height: 1rem;
}

.clr-control-container.fullwidth {
  width: 100%;
}
.clr-control-container.fullwidth input,
.clr-control-container.fullwidth textarea {
  flex: 1;
  max-width: 100%;
  width: 100%;
}

.clr-input-custom {
  height: auto;
  max-height: none;
}
.clr-input-custom .clr-input-wrapper {
  max-height: none;
}

.u-form-fullwidth .clr-form-control .clr-input-wrapper {
  max-width: none;
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic-ext.woff2) format("woff2");
  unicode-range: u+0460-052f, u+1c80-1c88, u+20b4, u+2de0-2dff, u+a640-a69f, u+fe2e-fe2f;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic.woff2) format("woff2");
  unicode-range: u+0400-045f, u+0490-0491, u+04b0-04b1, u+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek-ext.woff2) format("woff2");
  unicode-range: u+1f??;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek.woff2) format("woff2");
  unicode-range: u+0370-03ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-vietnamese.woff2) format("woff2");
  unicode-range: u+0102-0103, u+0110-0111, u+0128-0129, u+0168-0169, u+01a0-01a1, u+01af-01b0, u+1ea0-1ef9, u+20ab;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin-ext.woff2) format("woff2");
  unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin.woff2) format("woff2");
  unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}
@font-face {
  font-display: swap;
  font-family: Arial size-adjust;
  font-style: normal;
  font-weight: 400;
  size-adjust: 109%;
  src: local("Arial");
}
.dropzone__image,
.dropzone__instructions,
.dropzone__shim {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dropzone {
  position: relative;
  background: #969696;
}
.dropzone::after {
  position: absolute;
  content: "Image not defined";
  color: #ffffff;
  font-size: 32px;
  line-height: 24px;
  top: calc(50% - 12px);
  text-align: center;
  width: 100%;
}

.dropzone__image {
  z-index: 1;
}

.dropzone__instructions {
  z-index: 2;
  box-sizing: border-box;
  border: 4px solid #aaaaaa;
  visibility: hidden;
  text-align: center;
  background: rgba(238, 238, 238, 0.8);
}

.dropzone__instructions--opened {
  visibility: visible;
}

.dropzone__instructions clr-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px auto auto -30px;
}

.dropzone__instructions div {
  position: absolute;
}

.dropzone__shim {
  z-index: 3;
  background: transparent;
  cursor: pointer;
}

.constrained {
  white-space: nowrap;
  overflow: hidden;
  max-width: 20em;
  text-overflow: ellipsis;
}

.c-videoPreviewWrapper {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #ffffff;
}

ng2-completer {
  display: block;
  border-bottom: 0 none !important;
  padding: 0 !important;
  background-image: url("../../assets/images/svg/icon-search.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 0 !important;
  background-size: auto !important;
  transition: none !important;
}
ng2-completer input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  margin: 0;
  padding: 0 0.3rem;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: 0 0;
  height: 1.2rem;
  color: #000000;
  color: var(--clr-forms-text-color, #000000);
  border-bottom: 0.05rem solid;
  border-bottom-color: #b3b3b3;
  border-bottom-color: var(--clr-forms-border-color, #b3b3b3);
  display: inline-block;
  padding: 0 0.3rem;
  max-height: 1.2rem;
  font-size: 0.65rem;
  outline: 0 none;
  text-indent: 0.8rem;
}
ng2-completer input:not([readonly]) {
  background: linear-gradient(to bottom, transparent 95%, var(--clr-forms-focused-color, #0072a3) 95%) no-repeat;
  background-size: 0 100%;
  transition: background-size 0.2s ease;
}
ng2-completer input:not([readonly]):focus {
  border-bottom-color: #0072a3;
  border-bottom-color: var(--clr-forms-focused-color, #0072a3);
  background-size: 100% 100%;
}

ng2-completer input {
  width: 100%;
}

ng2-completer.tag-search input {
  min-width: auto;
}

.image-editor {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.image-editor .edit-btn {
  margin-right: 6px;
  cursor: pointer;
  border: none;
}
.image-editor .modal-title {
  display: flex;
  justify-content: space-between;
}
.image-editor .modal-title .title {
  display: flex;
  gap: 1rem;
  padding: 5px;
  flex: 1;
}
.image-editor .modal-title .title__text,
.image-editor .modal-title .title clr-icon {
  font-size: 20px;
  color: #ffffff;
}
.image-editor .modal-title .alerts {
  display: flex;
  flex-direction: column;
  flex: auto;
  width: min-content;
  margin-top: -16px;
}
.image-editor .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image-editor .modal-body .image-editor__body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  color: #ffffff;
}
.image-editor .modal-body .image-editor__body .image-editor-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-editor .modal-body .image-editor__body .image-editor-container,
.image-editor .modal-body .image-editor__body .checked-image {
  object-fit: contain;
  width: 60vw;
  height: 60vh;
}
.image-editor .modal-body .image-editor__body .crop-error .cropper-view-box {
  outline-color: #e62700;
}
.image-editor .modal-body .image-editor__body .crop-error .cropper-line,
.image-editor .modal-body .image-editor__body .crop-error .cropper-point {
  background-color: #e62700;
}
.image-editor .modal-body .btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.image-editor .modal-body .btn-container__action {
  align-items: end;
}
.image-editor .modal-body .btn-container__ratio {
  align-items: start;
}
.image-editor .modal-body .btn-container__ratio button {
  margin-left: 12px;
}
.image-editor .modal-body .decision-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.image-editor .modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1.2rem;
}
.image-editor .modal-dialog {
  width: 100vw;
  height: 100vh !important;
  max-height: 100vh !important;
}
.image-editor .modal-header--accessible {
  background-color: #444444;
}
.image-editor .modal-content {
  background-color: #333333 !important;
}
.image-editor .modal-backdrop {
  background-color: transparent;
}
.image-editor .cropper-point {
  width: 9px;
  height: 9px;
}
.image-editor .cropper-point.point-nw, .image-editor .cropper-point.point-n, .image-editor .cropper-point.point-ne {
  top: -5px;
}
.image-editor .cropper-point.point-w, .image-editor .cropper-point.point-e {
  margin-top: -5px;
}
.image-editor .cropper-point.point-sw, .image-editor .cropper-point.point-s, .image-editor .cropper-point.point-se {
  bottom: -5px;
}
.image-editor .cropper-point.point-nw, .image-editor .cropper-point.point-sw, .image-editor .cropper-point.point-w {
  left: -5px;
}
.image-editor .cropper-point.point-ne, .image-editor .cropper-point.point-se, .image-editor .cropper-point.point-e {
  right: -5px;
}
.image-editor .cropper-point.point-n, .image-editor .cropper-point.point-s {
  margin-left: -5px;
}
.image-editor .action-btn {
  background-color: #444444;
  color: #ffffff;
  border: none;
  outline: none;
}
.image-editor .action-btn clr-icon {
  color: #ffffff;
  width: 18px;
  height: 18px;
}
.image-editor .action-btn clr-icon.clr-icon__scale {
  transform: scale(-1, 1);
}
.image-editor .action-btn img.img__rotate {
  transform: rotate(90deg);
}
.image-editor .action-btn:hover, .image-editor .action-btn__active {
  background-color: #737373;
}

.owl-dt-container {
  font-size: 0.6rem;
}
.owl-dt-container .owl-dt-calendar {
  height: 21.25em;
}

.owl-dt-calendar-table {
  font-weight: bold;
  font-size: 14px !important;
}

.owl-dt-timer {
  height: 8em;
}

/* We need to keep this overrides for compatibility with clarity */
.cdk-overlay-container {
  z-index: 1060;
}

.cdk-global-scrollblock {
  top: 0 !important;
  overflow-y: auto;
}

#filterDate .owl-dt-container {
  font-size: 0.5rem;
  line-height: 14px;
  box-shadow: 0 0 0 transparent;
}

.tag-container .tag-selected {
  margin-bottom: 0;
  margin-top: 0;
}
.tag-container .tag-selected.label {
  background: #007cbb;
  border: 1px solid #007cbb;
  color: #ffffff;
}
.tag-container .tag-selected.label .badge {
  background: transparent;
}
.tag-container .u-colortheme--euronews .tag-selected.label {
  color: #ffffff;
}
.tag-container.label:hover {
  background: #007cbb;
  cursor: pointer;
}
.tag-container-group {
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  padding-left: 32px;
  width: 100%;
}
.tag-container-group__primary .tag-selected.label {
  background: #004a71;
  border: 1px solid #004a71;
  color: #ffffff;
}
.tag-container-group__primary .tag-selection:first-of-type .tag-selected.label {
  background: #48960c;
  border: 1px solid #48960c;
  color: #ffffff;
}
.tag-container-group__primary .tag-selection:first-of-type .tag-selected.label.label-orange {
  background: #f57600;
  border: 1px solid #f57600;
  color: #ffffff;
}
.tag-container-group__primary .tag-selected.label.label-orange {
  background: #f57600;
  border: 1px solid #f57600;
  color: #ffffff;
}
.tag-container-group__secondary .tag-selected.label {
  background: #00b7d6;
  border: 1px solid #00b7d6;
}

.suggested-tags {
  padding: 0;
}
.suggested-tags .badge {
  display: none;
  margin: 0 -9px 0 6px;
}
.suggested-tags .tag-selected.label:hover {
  background: #eeeeee;
}

.u-colortheme--euronews .tag-selected.label {
  border-color: #007cbb;
  border-radius: 10px;
  color: #007cbb;
}

.locked .checkbox input[type=checkbox]:checked + label::before {
  background: #fafafa;
}
.locked .checkbox input[type=checkbox] + label::before {
  background: #fafafa;
  border: 1px solid #cccccc;
}
.locked .checkbox label,
.locked .checkbox-inline label {
  color: #cccccc;
  cursor: not-allowed;
}
.locked #listEuronewsThemesSuggest,
.locked #listLivingThemesSuggest,
.locked .tag-container.theme-container .tag-selection,
.locked .tag-selection,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme {
  cursor: not-allowed;
}
.locked #listEuronewsThemesSuggest .tag-selected,
.locked #listLivingThemesSuggest .tag-selected,
.locked .tag-container.theme-container .tag-selection .tag-selected,
.locked .tag-selection .tag-selected,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected {
  cursor: not-allowed;
}
.locked #listEuronewsThemesSuggest .tag-selected .badge,
.locked #listLivingThemesSuggest .tag-selected .badge,
.locked .tag-container.theme-container .tag-selection .tag-selected .badge,
.locked .tag-selection .tag-selected .badge,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected .badge {
  display: none;
}
.locked #listEuronewsThemesSuggest .tag-selected.label, .locked #listEuronewsThemesSuggest .tag-selected.label.default-theme,
.locked #listLivingThemesSuggest .tag-selected.label,
.locked #listLivingThemesSuggest .tag-selected.label.default-theme,
.locked .tag-container.theme-container .tag-selection .tag-selected.label,
.locked .tag-container.theme-container .tag-selection .tag-selected.label.default-theme,
.locked .tag-selection .tag-selected.label,
.locked .tag-selection .tag-selected.label.default-theme,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label.default-theme {
  background: #fafafa;
  border: 1px solid #cccccc;
  color: #969696;
}
.locked #listEuronewsThemesSuggest .tag-selected.label:hover, .locked #listEuronewsThemesSuggest .tag-selected.label.default-theme:hover,
.locked #listLivingThemesSuggest .tag-selected.label:hover,
.locked #listLivingThemesSuggest .tag-selected.label.default-theme:hover,
.locked .tag-container.theme-container .tag-selection .tag-selected.label:hover,
.locked .tag-container.theme-container .tag-selection .tag-selected.label.default-theme:hover,
.locked .tag-selection .tag-selected.label:hover,
.locked .tag-selection .tag-selected.label.default-theme:hover,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label:hover,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label.default-theme:hover {
  background: #fafafa;
}
.locked #listEuronewsThemesSuggest .tag-selected.label span, .locked #listEuronewsThemesSuggest .tag-selected.label.default-theme span,
.locked #listLivingThemesSuggest .tag-selected.label span,
.locked #listLivingThemesSuggest .tag-selected.label.default-theme span,
.locked .tag-container.theme-container .tag-selection .tag-selected.label span,
.locked .tag-container.theme-container .tag-selection .tag-selected.label.default-theme span,
.locked .tag-selection .tag-selected.label span,
.locked .tag-selection .tag-selected.label.default-theme span,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label span,
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label.default-theme span {
  cursor: not-allowed;
}

.c-image_container {
  display: flex;
  width: 100%;
}
.c-image_container .clr-control-container {
  width: 100%;
}
.c-image_container.clr-form-control {
  flex-direction: row-reverse;
}
.c-image_container.clr-form-control .clr-control-label {
  align-self: center;
  height: 24px;
  margin: auto 0;
  max-width: 24px;
  min-width: 24px;
}
.c-image_container .clr-input-wrapper {
  display: flex;
  max-height: none;
  width: 100%;
}
.c-image_container .clr-input-wrapper .search-input {
  max-width: 30%;
}
.c-image_container .clr-input-wrapper cms-form-validator {
  flex: 1 0 70%;
  height: auto;
  max-height: none;
  max-width: 70%;
  white-space: pre;
}
.c-image_container_v2 ng2-completer {
  margin-right: 0;
}
.c-image_container_v2 .clr-input-wrapper {
  display: flex;
}

.tag-container.theme-container cms-draggable-badge .tag-selected.label.default-theme,
.themes-selection-container .selectedThemes .drag-theme .tag-selected.label.default-theme {
  background: #48960c !important;
  border: 1px solid #48960c !important;
  color: #ffffff !important;
}
.tag-container.theme-container cms-draggable-badge .tag-selected.label.default-theme .badge,
.themes-selection-container .selectedThemes .drag-theme .tag-selected.label.default-theme .badge {
  color: #ffffff;
}

.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label,
.locked.tag-container.theme-container .tag-selection .tag-selected.label {
  background: #fafafa;
  border: 1px solid #cccccc;
  color: #565656 !important;
  padding-right: 0.5rem;
}
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme .tag-selected.label span,
.locked.tag-container.theme-container .tag-selection .tag-selected.label span {
  cursor: not-allowed;
}
.locked .themes-selection-container .selectedThemes .select-theme.drag-theme:first-of-type .tag-selected.label,
.locked.tag-container.theme-container .tag-selection:first-of-type .tag-selected.label {
  border: 1px solid #48960c;
  color: #48960c;
}

.locked.tag-container .tag-selection {
  cursor: not-allowed;
}
.locked.tag-container .tag-selection .tag-selected.label {
  background: #fafafa;
  border: 1px solid #cccccc;
  color: #565656;
  padding-right: 0.5rem;
}
.locked.tag-container .tag-selection .tag-selected.label span {
  cursor: not-allowed;
}
.locked.tag-container .tag-selection:first-of-type .tag-selected.label {
  border: 1px solid #48960c;
  color: #48960c;
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic-ext.woff2) format("woff2");
  unicode-range: u+0460-052f, u+1c80-1c88, u+20b4, u+2de0-2dff, u+a640-a69f, u+fe2e-fe2f;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-cyrillic.woff2) format("woff2");
  unicode-range: u+0400-045f, u+0490-0491, u+04b0-04b1, u+2116;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek-ext.woff2) format("woff2");
  unicode-range: u+1f??;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-greek.woff2) format("woff2");
  unicode-range: u+0370-03ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-vietnamese.woff2) format("woff2");
  unicode-range: u+0102-0103, u+0110-0111, u+0128-0129, u+0168-0169, u+01a0-01a1, u+01af-01b0, u+1ea0-1ef9, u+20ab;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin-ext.woff2) format("woff2");
  unicode-range: u+0100-024f, u+0259, u+1e??, u+2020, u+20a0-20ab, u+20ad-20cf, u+2113, u+2c60-2c7f, u+a720-a7ff;
}
@font-face {
  font-display: swap;
  font-family: Inter;
  font-stretch: 75% 125%;
  font-style: normal;
  font-weight: 400 900;
  src: url(https://static.euronews.com/fonts/inter/inter-variable-latin.woff2) format("woff2");
  unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da, u+02dc, u+2000-206f, u+2074, u+20ac, u+2122, u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
}
@font-face {
  font-display: swap;
  font-family: Arial size-adjust;
  font-style: normal;
  font-weight: 400;
  size-adjust: 109%;
  src: local("Arial");
}
.ProseMirror {
  word-wrap: unset;
  white-space: unset;
  font-variant-ligatures: unset;
  font-feature-settings: unset;
  font-size: 16px;
  color: #1a1b1b;
}
.ProseMirror:focus {
  outline: none;
}
.ProseMirror > *,
.ProseMirror > h1,
.ProseMirror > h2,
.ProseMirror > h3,
.ProseMirror > h4,
.ProseMirror > h5,
.ProseMirror > h6, .ProseMirror:not(.default-styles) .ProseMirror *,
.ProseMirror:not(.default-styles) .ProseMirror h1,
.ProseMirror:not(.default-styles) .ProseMirror h2,
.ProseMirror:not(.default-styles) .ProseMirror h3,
.ProseMirror:not(.default-styles) .ProseMirror h4,
.ProseMirror:not(.default-styles) .ProseMirror h5,
.ProseMirror:not(.default-styles) .ProseMirror h6 {
  font-family: Inter, "Arial size-adjust", sans-serif;
}
.ProseMirror > h2,
.ProseMirror > h3,
.ProseMirror > h4, .ProseMirror:not(.default-styles) .ProseMirror h2,
.ProseMirror:not(.default-styles) .ProseMirror h3,
.ProseMirror:not(.default-styles) .ProseMirror h4 {
  margin: 0;
}
.ProseMirror > h2,
.ProseMirror > h3, .ProseMirror:not(.default-styles) .ProseMirror h2,
.ProseMirror:not(.default-styles) .ProseMirror h3 {
  padding: 1rem 0;
}
.ProseMirror > h2, .ProseMirror:not(.default-styles) .ProseMirror h2 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  padding: 1rem 0;
  clear: both;
  width: 100%;
}
.ProseMirror > h3, .ProseMirror:not(.default-styles) .ProseMirror h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
}
.ProseMirror > h4, .ProseMirror:not(.default-styles) .ProseMirror h4 {
  font-weight: 700;
  line-height: 1.3;
}
.ProseMirror > ol ol,
.ProseMirror > ul ul, .ProseMirror:not(.default-styles) .ProseMirror ol ol,
.ProseMirror:not(.default-styles) .ProseMirror ul ul {
  margin-left: 0;
  list-style: circle;
  clear: both;
}
.ProseMirror > ol,
.ProseMirror > ul, .ProseMirror:not(.default-styles) .ProseMirror ol,
.ProseMirror:not(.default-styles) .ProseMirror ul {
  margin: 1rem 0;
  padding: 0;
  font-size: 16px;
  clear: both;
}
.ProseMirror > ol li,
.ProseMirror > ul li, .ProseMirror:not(.default-styles) .ProseMirror ol li,
.ProseMirror:not(.default-styles) .ProseMirror ul li {
  margin-bottom: 12px;
}
.ProseMirror > ol li h2,
.ProseMirror > ol li h3,
.ProseMirror > ol li h4,
.ProseMirror > ul li h2,
.ProseMirror > ul li h3,
.ProseMirror > ul li h4, .ProseMirror:not(.default-styles) .ProseMirror ol li h2,
.ProseMirror:not(.default-styles) .ProseMirror ol li h3,
.ProseMirror:not(.default-styles) .ProseMirror ol li h4,
.ProseMirror:not(.default-styles) .ProseMirror ul li h2,
.ProseMirror:not(.default-styles) .ProseMirror ul li h3,
.ProseMirror:not(.default-styles) .ProseMirror ul li h4 {
  display: inline-block;
  width: auto;
}
.ProseMirror > p:not([cds-text]), .ProseMirror:not(.default-styles) .ProseMirror p:not([cds-text]) {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #1a1b1b;
  margin-top: 0;
  margin-bottom: 16px;
  word-wrap: normal;
  overflow-wrap: normal;
}
.ProseMirror > p:not([cds-text]).is-editor-empty:first-child::before, .ProseMirror:not(.default-styles) .ProseMirror p:not([cds-text]).is-editor-empty:first-child::before {
  color: #aaaaaa;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-style: italic;
}
[dir=rtl] .ProseMirror > p:not([cds-text]).is-editor-empty:first-child::before, [dir=rtl] .ProseMirror:not(.default-styles) .ProseMirror p:not([cds-text]).is-editor-empty:first-child::before {
  float: right;
}
.ProseMirror > * a, .ProseMirror:not(.default-styles) .ProseMirror * a {
  color: #0172f0;
  transition: color 0.2s ease-in-out;
  position: relative;
}
.ProseMirror > * a:hover, .ProseMirror:not(.default-styles) .ProseMirror * a:hover {
  color: #62adf6;
}
.ProseMirror > * a:visited, .ProseMirror:not(.default-styles) .ProseMirror * a:visited {
  color: #0172f0;
}
.ProseMirror > * a[tooltip]:hover::after, .ProseMirror > * a[tooltip]:hover::before, .ProseMirror:not(.default-styles) .ProseMirror * a[tooltip]:hover::after, .ProseMirror:not(.default-styles) .ProseMirror * a[tooltip]:hover::before {
  z-index: 10;
  position: absolute;
  left: 50%;
  display: block;
  background: #313131;
}
.ProseMirror > * a[tooltip]:hover::after, .ProseMirror:not(.default-styles) .ProseMirror * a[tooltip]:hover::after {
  content: attr(tooltip);
  top: calc(100% + 10px);
  width: max-content;
  max-width: 280px;
  padding: 3px 8px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.4));
  transform: translateX(-50%);
}
.ProseMirror > * a[tooltip]:hover::before, .ProseMirror:not(.default-styles) .ProseMirror * a[tooltip]:hover::before {
  content: "";
  top: 100%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, 50%) rotate(45deg);
}
.ProseMirror .widget-new {
  display: block;
  margin: 0 auto 1.2em;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  min-height: 1em;
  transition: background-color 1s ease-in;
}
.ProseMirror .widget-new:hover {
  background: #cccccc;
}
.ProseMirror .widget-new *::selection, .ProseMirror .widget-new::selection {
  background: transparent;
  color: inherit;
}
.ProseMirror .widget-new__overlay {
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0);
  transition: background 0.25s ease-in-out, border 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: grab;
}
.ProseMirror .widget-new__error-background {
  background-color: rgba(0, 0, 0, 0.1);
}
.ProseMirror .widget-new__contents {
  text-align: center;
}
.ProseMirror .widget-new__contents > iframe {
  margin: 0 auto !important;
}
.ProseMirror .widget-new__name {
  position: absolute;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.2em;
  padding: 0.2em;
  background: rgba(255, 255, 255, 0.7);
  color: #1a1b1b;
  font-size: 11px;
  line-height: 1;
  white-space: nowrap;
}
.ProseMirror .widget-new__figure {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}
.ProseMirror .widget-new__figure + br {
  display: none;
}
.ProseMirror .widget-new__caption {
  display: block;
  margin: 0 0 8px;
  padding: 0;
  text-align: center;
  width: 100%;
}
.ProseMirror .widget-new__captionWrap {
  color: #565656;
  display: inline-block;
  font-size: 12px;
  line-height: 1.4;
  padding-top: 0.4em;
  text-align: left;
}
.ProseMirror .widget-new__captionWrap a {
  color: #565656;
}
.ProseMirror .widget-new__captionCredit:empty, .ProseMirror .widget-new__captionText:empty, .ProseMirror .widget-new__captionWrap:empty {
  display: none;
}
[dir=rtl] .ProseMirror .widget-new__captionWrap {
  text-align: center;
}
.ProseMirror-focused .has-focus .widget-new {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.ProseMirror-focused .has-focus .widget-new__overlay {
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0.2);
}
.ProseMirror .widget-new--align-outdent-left, .ProseMirror .widget-new--align-outdent-right {
  z-index: 2;
}
.ProseMirror .widget-new--align-outdent-left::before, .ProseMirror .widget-new--align-outdent-right::before {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  width: 97px;
  background: rgba(0, 0, 0, 0.2);
  content: "↩";
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  padding-top: 0.5em;
}
.ProseMirror .widget-new--align-outdent-right::before {
  left: auto;
  right: 0;
  content: "↪";
}
.ProseMirror .widget-new--align-left, .ProseMirror .widget-new--align-outdent-left {
  float: left;
  margin-left: 0;
  margin-right: 1em;
}
.ProseMirror .widget-new--align-outdent-left {
  margin-left: -15%;
}
.ProseMirror .widget-new--align-outdent-right, .ProseMirror .widget-new--align-right {
  float: right;
  margin-left: 1em;
  margin-right: 0;
}
.ProseMirror .widget-new--align-outdent-right {
  margin-right: -15%;
}
.ProseMirror .widget-new--align-center, p + .ProseMirror .widget-new--align-outdent-left, p + .ProseMirror .widget-new--align-outdent-right {
  clear: both;
}
.ProseMirror .widget-new--align-center {
  float: none;
}
.ProseMirror .widget-new--size-xsmall {
  width: 25%;
}
.ProseMirror .widget-new--size-small {
  width: 33.333333%;
}
.ProseMirror .widget-new--size-medium {
  width: 50%;
}
.ProseMirror .widget-new--size-large {
  width: 66.666666%;
}
.ProseMirror .widget-new--size-xlarge {
  width: 75%;
}
.ProseMirror .widget-new--size-fullwidth {
  width: 100%;
  clear: both;
}
.ProseMirror .widget-new--size-fullscreen, .ProseMirror .widget-new--size-extended {
  width: 130% !important;
  float: none !important;
  clear: both !important;
  margin-left: -15% !important;
  margin-right: -15% !important;
  z-index: 1;
}
.ProseMirror .widget-new--size-fullscreen::after, .ProseMirror .widget-new--size-fullscreen::before, .ProseMirror .widget-new--size-extended::after, .ProseMirror .widget-new--size-extended::before {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
  width: 97px;
  background: rgba(0, 0, 0, 0.2);
  content: "↩";
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  font-size: 32px;
  font-weight: lighter;
  text-align: center;
  padding-top: 0.5em;
}
.ProseMirror .widget-new--size-fullscreen::before, .ProseMirror .widget-new--size-extended::before {
  left: auto;
  right: 0;
  content: "↪";
}
.ProseMirror .widget-new--size-extended::before {
  content: "⇤";
}
.ProseMirror .widget-new--size-extended::after {
  content: "⇥";
}
.ProseMirror .widget-new--infinite-scroll {
  position: relative;
}
.ProseMirror .widget-new--infinite-scroll::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%), linear-gradient(-45deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%), linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  background-position: bottom center, bottom center, bottom center;
  background-size: 10px 10px, 10px 10px, 100% 16px;
  background-repeat: repeat-x;
}
.ProseMirror .breakpoint--medium .widget-new--size-xsmall, .ProseMirror .breakpoint--medium .widget-new--size-small, .ProseMirror .breakpoint--medium .widget-new--size-medium, .ProseMirror .breakpoint--medium .widget-new--size-large, .ProseMirror .breakpoint--medium .widget-new--size-xlarge, .ProseMirror .breakpoint--medium .widget-new--size-fullsize, .ProseMirror .breakpoint--medium .widget-new--size-extended, .ProseMirror .breakpoint--medium .widget-new--size-fullscreen,
.ProseMirror .breakpoint--small .widget-new--size-xsmall,
.ProseMirror .breakpoint--small .widget-new--size-small,
.ProseMirror .breakpoint--small .widget-new--size-medium,
.ProseMirror .breakpoint--small .widget-new--size-large,
.ProseMirror .breakpoint--small .widget-new--size-xlarge,
.ProseMirror .breakpoint--small .widget-new--size-fullsize,
.ProseMirror .breakpoint--small .widget-new--size-extended,
.ProseMirror .breakpoint--small .widget-new--size-fullscreen {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ProseMirror .breakpoint--medium .widget-new--align-outdent-left, .ProseMirror .breakpoint--medium .widget-new--align-left, .ProseMirror .breakpoint--medium .widget-new--align-center, .ProseMirror .breakpoint--medium .widget-new--align-right, .ProseMirror .breakpoint--medium .widget-new--align-outdent-right,
.ProseMirror .breakpoint--small .widget-new--align-outdent-left,
.ProseMirror .breakpoint--small .widget-new--align-left,
.ProseMirror .breakpoint--small .widget-new--align-center,
.ProseMirror .breakpoint--small .widget-new--align-right,
.ProseMirror .breakpoint--small .widget-new--align-outdent-right {
  float: none !important;
  clear: both !important;
  margin: 0 auto 1.2em auto !important;
}
.ProseMirror .breakpoint--medium .widget-new--align-outdent-left ::after,
.ProseMirror .breakpoint--medium .widget-new--align-outdent-left ::before, .ProseMirror .breakpoint--medium .widget-new--align-outdent-right ::after,
.ProseMirror .breakpoint--medium .widget-new--align-outdent-right ::before, .ProseMirror .breakpoint--medium .widget-new--size-fullscreen ::after,
.ProseMirror .breakpoint--medium .widget-new--size-fullscreen ::before,
.ProseMirror .breakpoint--small .widget-new--align-outdent-left ::after,
.ProseMirror .breakpoint--small .widget-new--align-outdent-left ::before,
.ProseMirror .breakpoint--small .widget-new--align-outdent-right ::after,
.ProseMirror .breakpoint--small .widget-new--align-outdent-right ::before,
.ProseMirror .breakpoint--small .widget-new--size-fullscreen ::after,
.ProseMirror .breakpoint--small .widget-new--size-fullscreen ::before {
  display: none;
}
.ProseMirror .widget-new--type-tweet {
  min-width: 250px;
  max-width: 500px;
}
.ProseMirror .widget-new--type-tweet .twitter-tweet {
  margin: 0 auto !important;
}
.ProseMirror .widget-new--type-freeform .widget-new__contents {
  text-align: left;
}
.ProseMirror .widget-new--type-freeform .widget-new__contents > * {
  max-width: 100% !important;
}
.ProseMirror .widget-new--type-freeform iframe {
  max-width: 100% !important;
  position: relative;
}
[dir=rtl] .ProseMirror .widget-new--type-quotation_v2 .quote::before {
  left: auto;
  right: 0;
}
[dir=rtl] .ProseMirror .widget-new--type-quotation_v2 .quote::after {
  transform: scaleX(-1);
}
.ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Related";
}
[lang=fr] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Lire aussi";
}
[lang=de] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Lesen Sie auch";
}
[lang=it] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Per appronfondire";
}
[lang=es] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Leer";
}
[lang=pt] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Notícias relacionadas";
}
[lang=tr] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "İLGİLİ HABERLER";
}
[lang=ru] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Читать также";
}
[lang=ar] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "موضوعات متعلقة";
}
[lang=fa] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "بیشتر بخوانید";
}
[lang=el] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Διαβάστε επίσης";
}
[lang=hu] .ProseMirror .widget-new--type-related ul:not(:empty)::before {
  content: "Kapcsolódó cikkünk";
}
[dir=rtl] .ProseMirror .widget-new--type-related ul:not(:empty) {
  margin-left: 0 !important;
  margin-right: 24px !important;
  text-align: right;
}
[dir=rtl] .ProseMirror .widget-new--type-related ul:not(:empty)::after {
  right: 0;
}
[dir=rtl] .ProseMirror .widget-new--type-related li:not(:empty) {
  margin-left: 16px;
  margin-right: 0;
}
[dir=rtl] .ProseMirror .widget-new--type-related li:not(:empty)::after {
  margin-left: 0;
  margin-right: 20px;
  transform: rotate(180deg) scale(1.2);
}
.ProseMirror c {
  text-decoration: underline;
  text-decoration-thickness: 0;
  text-decoration-color: transparent;
  transition: text-decoration 0.2s, background-position 0.1s;
}
.breakpoint--xlarge .ProseMirror.display-comments c {
  text-decoration-thickness: 2px;
  text-decoration-color: #fac400;
  background: linear-gradient(transparent 50%, rgba(250, 196, 0, 0.3) 50%);
  background-size: 100% 200%;
}
.breakpoint--xlarge .ProseMirror.display-comments c.active {
  background-position: 100% 100%;
}
:not(.breakpoint--xlarge) .ProseMirror cms-wysiwyg-comments-list {
  display: none;
}
.breakpoint--xlarge .ProseMirror cms-wysiwyg-comments-list {
  display: inline;
}
.ProseMirror cms-wysiwyg-comments-list .comments-container .comments-list > div {
  display: flex;
  flex-direction: column;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container .comments-list-toggle,
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container .comments-list {
  transform: translateX(calc(-100% - 20px));
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container .comments-list-toggle {
  left: 0;
  right: unset;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container .comments-list {
  mask-position: 100% -30px;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container.display-comments .comments-list-toggle {
  transform: translateX(calc(-100% - 20px - 300px));
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container.display-comments .comments-list.fade-top {
  mask-position: 100% 0;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container.display-comments .comments-list.fade-bottom {
  mask-position: 100% -30px;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-list .comments-container.display-comments .comments-list.fade-top.fade-bottom {
  mask-size: 100% 100%;
  mask-position: 100% 0;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-thread .delete-thread {
  left: 2px;
  right: unset;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comments-thread .comment-thread .new-comment .new-comment-input::placeholder {
  text-align: right;
}
[dir=rtl] .ProseMirror cms-wysiwyg-comment .delete-comment {
  left: 0;
  right: unset;
}

.prosemirror-dropcursor-block {
  background-color: transparent !important;
  border-top: 3px dotted #017ff8;
}

[id^=tippy-] {
  z-index: 9 !important;
}
[id^=tippy-] .tippy-box {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.4));
  max-width: 650px !important;
}
[id^=tippy-] .tippy-box::after, [id^=tippy-] .tippy-box::before {
  content: "";
  display: none;
  position: absolute;
  z-index: -1;
  width: 10px;
  height: 10px;
  background-color: #313131;
}
[id^=tippy-] .tippy-box[data-placement=top]::after {
  display: block;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
}
[id^=tippy-] .tippy-box[data-placement=bottom]::after {
  display: block;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, 50%) rotate(45deg);
}
[id^=tippy-] .tippy-box[data-placement=left]::after {
  display: block;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
[id^=tippy-] .tippy-box[data-placement=right]::after {
  display: block;
  right: 100%;
  top: 50%;
  transform: translate(50%, -50%) rotate(45deg);
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu {
  border-radius: 5px;
  display: flex;
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #313131;
  color: #ffffff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  outline: none;
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button:first-child,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button:last-child,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button:not(:last-child),
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button.active, [id^=tippy-] .tippy-box .tippy-content .floating-menu button:hover,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button.active,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button:hover {
  color: #62adf6;
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button.active,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button.active {
  background: #1a1b1b;
  border-right-color: rgba(255, 255, 255, 0.17);
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button[disabled],
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button[disabled] {
  cursor: not-allowed;
}
[id^=tippy-] .tippy-box .tippy-content .floating-menu button .text-icon,
[id^=tippy-] .tippy-box .tippy-content .bubble-menu button .text-icon {
  font-size: 16px;
  font-weight: bold;
}

.tippy-arrow {
  transform-style: preserve-3d;
}
.tippy-arrow::after {
  content: "";
  position: absolute;
  left: -8px;
  transform: translateZ(-1px);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

body {
  background: #fafafa;
  overflow: hidden;
}

.c-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.c-content {
  margin-top: 32px;
}

.c-icon-title {
  margin-right: 12px;
}

.main-container .content-container .sidenav {
  overflow: visible;
  z-index: 502;
}

.completer-dropdown {
  max-height: 22em;
  overflow-y: scroll;
  overflow-x: hidden;
}

.clr-wizard .modal-content {
  align-items: inherit;
}

.datagrid .datagrid-row {
  border-top: none;
}
.datagrid .datagrid-table .datagrid-cell.datagrid-row-actions,
.datagrid .datagrid-row-scrollable {
  border-top: 0.05rem solid #cccccc;
}
.datagrid .datagrid-row-flex,
.datagrid .datagrid-row-scrollable {
  background-color: inherit;
}
.datagrid .datagrid-table .datagrid-cell:focus,
.datagrid .datagrid-table .datagrid-column:focus {
  outline: none;
}
.modal-body .datagrid {
  margin-top: 0;
}
.no-header .datagrid .datagrid-header {
  display: none;
}
.datagrid-detail-open .datagrid .datagrid-row-scrollable {
  overflow: hidden;
}
.datagrid .datagrid-row-detail-open .datagrid-row-sticky,
.datagrid .datagrid-row-detail-open .datagrid-row-scrollable {
  background-color: #e1f1f6;
}

.datagrid-outer-wrapper {
  flex-grow: unset !important;
}

ul.list-unstyled:not([cds-list]) {
  list-style: none;
}

.t-africanews .show-for-africanews {
  display: block;
}
.t-africanews .show-for-euronews {
  display: none;
}
.t-africanews .hide-for-africanews {
  display: none !important;
}

.u-background--africanews {
  background: #ffffff;
}

.t-euronews .show-for-euronews {
  display: block;
}
.t-euronews .show-for-africanews {
  display: none;
}
.t-euronews .hide-for-euronews {
  display: none !important;
}

.u-background--euronews {
  background: #eeeeee;
}

.u-no-border-top {
  border-top: none !important;
}

.black,
.is-std {
  color: #000000;
}

.grey {
  color: #565656;
}

.green,
.is-success {
  color: #48960c;
}

.blue,
.is-info {
  color: #007cbb;
}

.dark-blue {
  color: #003d79;
}

.red,
.is-error {
  color: #e62700;
}

.jaune-africa {
  color: #f9d713;
}

.jaune-fonce-africa {
  color: #e7b322;
}

.orange,
.is-warning {
  color: #fac400;
}

.orange-hot,
.is-warning-hot {
  color: #f57600;
}

.bg-grey {
  background-color: #fafafa;
}

.eventStatus,
.eventLangage {
  font-weight: bold;
}
.eventStatus clr-icon,
.eventLangage clr-icon {
  margin-right: 6px;
}
.eventStatus--new, .eventStatus--new a,
.eventLangage--new,
.eventLangage--new a {
  color: #007cbb;
}
.eventStatus--edit, .eventStatus--edit a,
.eventLangage--edit,
.eventLangage--edit a {
  color: #004a71;
}
.eventStatus--scheduled, .eventStatus--scheduled a,
.eventLangage--scheduled,
.eventLangage--scheduled a {
  color: #f57600;
}
.eventStatus--to_republish, .eventStatus--to_republish a,
.eventLangage--to_republish,
.eventLangage--to_republish a {
  color: #fac400;
}
.eventStatus--online, .eventStatus--online a,
.eventLangage--online,
.eventLangage--online a {
  color: #48960c;
}
.eventStatus--offline, .eventStatus--offline a,
.eventLangage--offline,
.eventLangage--offline a {
  color: #e62700;
}
.eventStatus--in_translation, .eventStatus--in_translation a,
.eventLangage--in_translation,
.eventLangage--in_translation a {
  color: #565656;
}
.eventStatus--in_duplication, .eventStatus--in_duplication a,
.eventLangage--in_duplication,
.eventLangage--in_duplication a {
  color: #565656;
}
.eventStatus--duplicated, .eventStatus--duplicated a,
.eventLangage--duplicated,
.eventLangage--duplicated a {
  color: #565656;
}
.eventStatus--awaiting, .eventStatus--awaiting a,
.eventLangage--awaiting,
.eventLangage--awaiting a {
  color: #f57600;
}
.eventStatus--ready_to_publish, .eventStatus--ready_to_publish a,
.eventLangage--ready_to_publish,
.eventLangage--ready_to_publish a {
  color: #fac400;
}
.eventStatus--submitted, .eventStatus--submitted a,
.eventLangage--submitted,
.eventLangage--submitted a {
  color: #003d79;
}
.eventStatus--approved, .eventStatus--approved a,
.eventLangage--approved,
.eventLangage--approved a {
  color: #266900;
}
.eventStatus--new, .eventStatus--new a,
.eventLangage--new,
.eventLangage--new a {
  color: #007cbb;
}
.eventStatus--pth, .eventStatus--pth a,
.eventLangage--pth,
.eventLangage--pth a {
  color: #48960c;
}
.eventStatus--das, .eventStatus--das a,
.eventLangage--das,
.eventLangage--das a {
  color: #f57600;
}
.eventStatus--wip, .eventStatus--wip a,
.eventLangage--wip,
.eventLangage--wip a {
  color: #fac400;
}
.eventStatus--psb, .eventStatus--psb a,
.eventLangage--psb,
.eventLangage--psb a {
  color: #e88c8c;
}
.eventStatus--pap, .eventStatus--pap a,
.eventLangage--pap,
.eventLangage--pap a {
  color: #e62700;
}
.eventStatus--loc, .eventStatus--loc a,
.eventLangage--loc,
.eventLangage--loc a {
  color: #004a71;
}
.eventStatus--aal, .eventStatus--aal a,
.eventLangage--aal,
.eventLangage--aal a {
  color: #007cbb;
}
.eventStatus--pal, .eventStatus--pal a,
.eventLangage--pal,
.eventLangage--pal a {
  color: #781da0;
}
.eventStatus--cls, .eventStatus--cls a,
.eventLangage--cls,
.eventLangage--cls a {
  color: #565656;
}

.u-font-grey {
  color: #565656 !important;
}

.u-inline-block {
  display: inline-block;
}

.u-block {
  display: block;
}

.u-clear {
  clear: both;
}

.u-none {
  display: none;
}

.u-flex {
  display: flex;
}

.u-cell-flex {
  display: flex;
}
.u-cell-flex > * {
  margin: 0 8px auto 0;
}
.u-cell-flex img {
  margin-top: -4px;
  min-width: 20px;
  height: 20px;
}

.u-column {
  flex-direction: column;
}

.u-row {
  flex-direction: row;
}

.u-flex--1 {
  flex: 1 0 auto !important;
}

.u-flex--spacebetween {
  justify-content: space-between;
}

.u-form-fullwidth {
  width: 100%;
}
.u-form-fullwidth .clr-control-container {
  width: 100%;
  max-width: 100%;
  flex: 1;
}
.u-form-fullwidth input,
.u-form-fullwidth textarea {
  width: 100%;
}

.required::after {
  color: red;
  content: "*";
}

.clr-radio-wrapper {
  display: block;
}

.clr-radio-wrapper label {
  display: inline-block !important;
}

.clr-form,
.clr-form-group {
  padding: 0.25rem 0 !important;
}

.btn-group .btn input[type=radio]:focus,
.btn-group .btn input[type=checkbox]:focus {
  opacity: 0;
}

section > .clr-form-control {
  margin-top: 0;
}

section > .clr-form-control ~ .clr-form-control {
  margin-top: 1rem;
}

td .clr-form-control > .clr-control-label {
  width: auto !important;
}

.tag-container .clr-input-wrapper {
  max-width: 100%;
  max-height: none;
}

.clr-form-control.clr-row {
  margin-left: 0;
  margin-right: 0;
}

.u-no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-no-gutter-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-no-gutter-left {
  padding-left: 0 !important;
}

.u--no-gutter-left-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-gutter-24 {
  padding: 24px;
}

.image__ratio {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.image__ratio > img,
.image__ratio > iframe,
.image__ratio > video,
.image__ratio > cms-image-preview,
.image__ratio > .no-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image__ratio--16x9 {
  padding: 56.25% 0 0 0;
}

.image__ratio--4x3 {
  padding: 75% 0 0 0;
}

.image__ratio--1x1 {
  padding: 100% 0 0 0;
}

.u-no-padding {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.u-no-margin {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
}

.u--no-spacing {
  padding: 0 !important;
  margin: 0 !important;
}

.u--no-margin {
  margin: 0;
}

.u--no-margin th {
  line-height: 24px;
}

.u-no-margin-left {
  margin-left: 0 !important;
  margin-right: auto;
}

.u-no-margin-right {
  margin-right: 0 !important;
}

.u-no-margin-top {
  margin-top: 0 !important;
}

.u-no-margin-bottom {
  margin-bottom: 0 !important;
}

.u-no-padding-left {
  padding-left: 0 !important;
}

.u-no-padding-right {
  padding-right: 0 !important;
}

.u-no-padding-top {
  padding-top: 0 !important;
}

.u-no-padding-bottom {
  padding-bottom: 0 !important;
}

.u-text-overflow {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.u-no-scroll {
  overflow: hidden;
}

.locked {
  cursor: not-allowed;
}

.u-no-event {
  pointer-events: none;
}

.c-pointer {
  cursor: pointer;
}

.u-select-none {
  user-select: none;
}

.u-inner-shadow {
  box-shadow: 5px -2px 10px rgba(0, 0, 0, 0.15) inset;
}

.u-outer-shadow {
  box-shadow: 12px 0 12px rgba(0, 0, 0, 0.2);
}

.u-outer-shadow--soft {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

[dir=rtl] .u-inner-shadow {
  box-shadow: none;
}

.u-mw-50 {
  max-width: 50%;
}

.u-w100 {
  width: 100%;
}

.u-w100 .clr-input-wrapper {
  max-width: none;
}

.u-min-w100 {
  min-width: 100% !important;
  width: 100%;
}

.u-min-height-75vh {
  min-height: 75vh;
}

.u-min-height-100 {
  min-height: 6rem;
}

.u-m-w-150p {
  max-width: 150px !important;
}

.u-m-w-33 {
  max-width: 33.33% !important;
}

.u-w40p {
  width: 40px;
  max-width: 40px;
  min-width: 40px !important;
}

.u-w50p {
  width: 50px;
  max-width: 50px;
  min-width: 50px !important;
}

.u-w60p {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}

.u-w70p {
  width: 70px;
  max-width: 70px;
  min-width: 70px !important;
}

.u-w80p {
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}

.u-w100p {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

.u-w120p {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
}

.u-w135p {
  width: 135px;
  max-width: 135px;
  min-width: 135px;
}

.u-w150p {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.u-w160p {
  width: 160px;
  max-width: 160px;
  min-width: 160px;
}

.u-w170p {
  width: 170px;
  max-width: 170px;
  min-width: 170px;
}

.u-w200p {
  width: 200px;
  max-width: 200px;
  min-width: 200px;
}

.u-w250p {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}

.u-w350p {
  width: 350px;
  max-width: 350px;
  min-width: 350px;
}

.u-w450p {
  width: 450px;
  max-width: 450px;
  min-width: 450px;
}

.u-col-mw-150p {
  max-width: 150px !important;
}

.u-col-mw-380p {
  max-width: 380px !important;
}

.u-col-w60p {
  max-width: 60px !important;
}

.sidenav {
  z-index: 1;
  width: 50px;
  max-width: 50px;
  min-width: 50px;
  box-shadow: 0 0 16px #000000;
  border-right: 0 !important;
}

@media screen and (max-width: 1250px) {
  .u-col-mw-150p {
    max-width: 150px;
  }

  .u-col-mw-380p {
    max-width: 150px;
  }

  .u-col-w60p {
    max-width: 60px;
  }
}
.alerts-pager {
  width: 190px;
}

.u-align--center {
  margin: auto;
}

.u-ma-t-10p {
  margin-top: 10px;
}

.u-ma-t-16p {
  margin-top: 16px;
}

.u-ma-neg-x-10 {
  margin-left: -5px !important;
}

.u-ma-l-6p {
  margin-left: 6px;
}

.u-ma-l-12p {
  margin-left: 12px;
}

.u-padding-right-12p {
  padding-right: 12px;
}

.c-padding-left-w50 {
  padding-left: 50%;
}

.u-pa-b-14p {
  padding-bottom: 12px;
}

.u-margin-0 {
  margin: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-small-only-0 {
    margin: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-medium-down-0 {
    margin: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-medium-0 {
    margin: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-medium-only-0 {
    margin: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-large-0 {
    margin: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-large-only-0 {
    margin: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-xlarge-0 {
    margin: 0px !important;
  }
}

.u-margin-bottom-0 {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-bottom-small-only-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-bottom-medium-down-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-bottom-medium-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-bottom-medium-only-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-bottom-large-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-bottom-large-only-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-bottom-xlarge-0 {
    margin-bottom: 0px !important;
  }
}

.u-margin-bottom-2 {
  margin-bottom: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-bottom-small-only-2 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-bottom-medium-down-2 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-bottom-medium-2 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-bottom-medium-only-2 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-bottom-large-2 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-bottom-large-only-2 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-bottom-xlarge-2 {
    margin-bottom: 12px !important;
  }
}

.u-margin-left-0 {
  margin-left: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-left-small-only-0 {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-left-medium-down-0 {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-left-medium-0 {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-left-medium-only-0 {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-left-large-0 {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-left-large-only-0 {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-left-xlarge-0 {
    margin-left: 0px !important;
  }
}

.u-margin-left-1 {
  margin-left: 6px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-left-small-only-1 {
    margin-left: 6px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-left-medium-down-1 {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-left-medium-1 {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-left-medium-only-1 {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-left-large-1 {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-left-large-only-1 {
    margin-left: 6px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-left-xlarge-1 {
    margin-left: 6px !important;
  }
}

.u-margin-left-2 {
  margin-left: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-left-small-only-2 {
    margin-left: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-left-medium-down-2 {
    margin-left: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-left-medium-2 {
    margin-left: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-left-medium-only-2 {
    margin-left: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-left-large-2 {
    margin-left: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-left-large-only-2 {
    margin-left: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-left-xlarge-2 {
    margin-left: 12px !important;
  }
}

.u-margin-right-0 {
  margin-right: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-right-small-only-0 {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-right-medium-down-0 {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-right-medium-0 {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-right-medium-only-0 {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-right-large-0 {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-right-large-only-0 {
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-right-xlarge-0 {
    margin-right: 0px !important;
  }
}

.u-margin-right-1 {
  margin-right: 6px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-right-small-only-1 {
    margin-right: 6px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-right-medium-down-1 {
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-right-medium-1 {
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-right-medium-only-1 {
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-right-large-1 {
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-right-large-only-1 {
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-right-xlarge-1 {
    margin-right: 6px !important;
  }
}

.u-margin-right-2 {
  margin-right: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-right-small-only-2 {
    margin-right: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-right-medium-down-2 {
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-right-medium-2 {
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-right-medium-only-2 {
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-right-large-2 {
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-right-large-only-2 {
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-right-xlarge-2 {
    margin-right: 12px !important;
  }
}

.u-margin-top-0 {
  margin-top: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-top-small-only-0 {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-top-medium-down-0 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-top-medium-0 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-top-medium-only-0 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-top-large-0 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-top-large-only-0 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-top-xlarge-0 {
    margin-top: 0px !important;
  }
}

.u-margin-top-2 {
  margin-top: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-top-small-only-2 {
    margin-top: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-top-medium-down-2 {
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-top-medium-2 {
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-top-medium-only-2 {
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-top-large-2 {
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-top-large-only-2 {
    margin-top: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-top-xlarge-2 {
    margin-top: 12px !important;
  }
}

.u-margin-top-4 {
  margin-top: 24px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-top-small-only-4 {
    margin-top: 24px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-top-medium-down-4 {
    margin-top: 24px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-top-medium-4 {
    margin-top: 24px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-top-medium-only-4 {
    margin-top: 24px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-top-large-4 {
    margin-top: 24px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-top-large-only-4 {
    margin-top: 24px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-top-xlarge-4 {
    margin-top: 24px !important;
  }
}

.u-margin-x-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-x-small-only-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-x-medium-down-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-x-medium-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-x-medium-only-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-x-large-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-x-large-only-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-x-xlarge-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.u-margin-x-1 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-x-small-only-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-x-medium-down-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-x-medium-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-x-medium-only-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-x-large-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-x-large-only-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-x-xlarge-1 {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

.u-margin-x-2 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-x-small-only-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-x-medium-down-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-x-medium-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-x-medium-only-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-x-large-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-x-large-only-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-x-xlarge-2 {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

.u-margin-y-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-y-small-only-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-y-medium-down-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-y-medium-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-y-medium-only-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-y-large-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-y-large-only-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-y-xlarge-0 {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}

.u-margin-y-3 {
  margin-bottom: 18px !important;
  margin-top: 18px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-y-small-only-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-y-medium-down-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-y-medium-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-y-medium-only-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-y-large-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-y-large-only-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-y-xlarge-3 {
    margin-bottom: 18px !important;
    margin-top: 18px !important;
  }
}

.u-margin-end-0 {
  margin-right: 0px !important;
}
.u-margin-end-0[dir=rtl] {
  margin-left: 0px !important;
  margin-right: 0;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-end-small-only-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-small-only-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-end-medium-down-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-medium-down-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-end-medium-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-medium-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-end-medium-only-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-medium-only-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-end-large-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-large-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-end-large-only-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-large-only-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-end-xlarge-0 {
    margin-right: 0px !important;
  }
  .u-margin-end-xlarge-0[dir=rtl] {
    margin-left: 0px !important;
    margin-right: 0;
  }
}

.u-margin-start-0 {
  margin-left: 0px !important;
}
.u-margin-start-0[dir=rtl] {
  margin-left: 0;
  margin-right: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-margin-start-small-only-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-small-only-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-margin-start-medium-down-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-medium-down-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-margin-start-medium-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-medium-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-margin-start-medium-only-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-medium-only-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-margin-start-large-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-large-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-margin-start-large-only-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-large-only-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-margin-start-xlarge-0 {
    margin-left: 0px !important;
  }
  .u-margin-start-xlarge-0[dir=rtl] {
    margin-left: 0;
    margin-right: 0px !important;
  }
}

.u-padding-0 {
  padding: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-small-only-0 {
    padding: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-medium-down-0 {
    padding: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-medium-0 {
    padding: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-medium-only-0 {
    padding: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-large-0 {
    padding: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-large-only-0 {
    padding: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-xlarge-0 {
    padding: 0px !important;
  }
}

.u-padding-3 {
  padding: 18px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-small-only-3 {
    padding: 18px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-medium-down-3 {
    padding: 18px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-medium-3 {
    padding: 18px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-medium-only-3 {
    padding: 18px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-large-3 {
    padding: 18px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-large-only-3 {
    padding: 18px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-xlarge-3 {
    padding: 18px !important;
  }
}

.u-padding-bottom-0 {
  padding-bottom: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-bottom-small-only-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-bottom-medium-down-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-bottom-medium-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-bottom-medium-only-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-bottom-large-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-bottom-large-only-0 {
    padding-bottom: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-bottom-xlarge-0 {
    padding-bottom: 0px !important;
  }
}

.u-padding-bottom-2 {
  padding-bottom: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-bottom-small-only-2 {
    padding-bottom: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-bottom-medium-down-2 {
    padding-bottom: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-bottom-medium-2 {
    padding-bottom: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-bottom-medium-only-2 {
    padding-bottom: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-bottom-large-2 {
    padding-bottom: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-bottom-large-only-2 {
    padding-bottom: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-bottom-xlarge-2 {
    padding-bottom: 12px !important;
  }
}

.u-padding-right-0 {
  padding-right: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-right-small-only-0 {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-right-medium-down-0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-right-medium-0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-right-medium-only-0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-right-large-0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-right-large-only-0 {
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-right-xlarge-0 {
    padding-right: 0px !important;
  }
}

.u-padding-right-2 {
  padding-right: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-right-small-only-2 {
    padding-right: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-right-medium-down-2 {
    padding-right: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-right-medium-2 {
    padding-right: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-right-medium-only-2 {
    padding-right: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-right-large-2 {
    padding-right: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-right-large-only-2 {
    padding-right: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-right-xlarge-2 {
    padding-right: 12px !important;
  }
}

.u-padding-left-0 {
  padding-left: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-left-small-only-0 {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-left-medium-down-0 {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-left-medium-0 {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-left-medium-only-0 {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-left-large-0 {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-left-large-only-0 {
    padding-left: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-left-xlarge-0 {
    padding-left: 0px !important;
  }
}

.u-padding-top-0 {
  padding-top: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-top-small-only-0 {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-top-medium-down-0 {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-top-medium-0 {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-top-medium-only-0 {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-top-large-0 {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-top-large-only-0 {
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-top-xlarge-0 {
    padding-top: 0px !important;
  }
}

.u-padding-top-2 {
  padding-top: 12px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-top-small-only-2 {
    padding-top: 12px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-top-medium-down-2 {
    padding-top: 12px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-top-medium-2 {
    padding-top: 12px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-top-medium-only-2 {
    padding-top: 12px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-top-large-2 {
    padding-top: 12px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-top-large-only-2 {
    padding-top: 12px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-top-xlarge-2 {
    padding-top: 12px !important;
  }
}

.u-padding-bottom-7 {
  padding-bottom: 42px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-bottom-small-only-7 {
    padding-bottom: 42px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-bottom-medium-down-7 {
    padding-bottom: 42px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-bottom-medium-7 {
    padding-bottom: 42px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-bottom-medium-only-7 {
    padding-bottom: 42px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-bottom-large-7 {
    padding-bottom: 42px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-bottom-large-only-7 {
    padding-bottom: 42px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-bottom-xlarge-7 {
    padding-bottom: 42px !important;
  }
}

.u-padding-x-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-x-small-only-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-x-medium-down-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-x-medium-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-x-medium-only-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-x-large-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-x-large-only-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-x-xlarge-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.u-padding-x-3 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-x-small-only-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-x-medium-down-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-x-medium-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-x-medium-only-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-x-large-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-x-large-only-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-x-xlarge-3 {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

.u-padding-y-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-y-small-only-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-y-medium-down-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-y-medium-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-y-medium-only-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-y-large-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-y-large-only-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-y-xlarge-0 {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

.u-padding-y-3 {
  padding-bottom: 18px !important;
  padding-top: 18px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-y-small-only-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-y-medium-down-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-y-medium-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-y-medium-only-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-y-large-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-y-large-only-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-y-xlarge-3 {
    padding-bottom: 18px !important;
    padding-top: 18px !important;
  }
}

.u-padding-end-0 {
  padding-right: 0px !important;
}
.u-padding-end-0[dir=rtl] {
  padding-left: 0px !important;
  padding-right: 0;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-end-small-only-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-small-only-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-end-medium-down-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-medium-down-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-end-medium-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-medium-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-end-medium-only-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-medium-only-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-end-large-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-large-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-end-large-only-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-large-only-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-end-xlarge-0 {
    padding-right: 0px !important;
  }
  .u-padding-end-xlarge-0[dir=rtl] {
    padding-left: 0px !important;
    padding-right: 0;
  }
}

.u-padding-start-1 {
  padding-left: 6px !important;
}
.u-padding-start-1[dir=rtl] {
  padding-left: 0;
  padding-right: 6px !important;
}

@media screen and (max-width: 39.9375em) {
  .u-padding-start-small-only-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-small-only-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .u-padding-start-medium-down-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-medium-down-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

@media screen and (min-width: 40em) {
  .u-padding-start-medium-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-medium-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .u-padding-start-medium-only-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-medium-only-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

@media screen and (min-width: 64em) {
  .u-padding-start-large-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-large-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .u-padding-start-large-only-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-large-only-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

@media screen and (min-width: 75em) {
  .u-padding-start-xlarge-1 {
    padding-left: 6px !important;
  }
  .u-padding-start-xlarge-1[dir=rtl] {
    padding-left: 0;
    padding-right: 6px !important;
  }
}

.u-no-padding-x {
  padding-left: auto;
  padding-right: auto;
}

.u-margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

a.storyStatus,
.storyStatus {
  position: static;
  color: #007cbb;
  padding: 2px;
}
.langageStatus a.storyStatus,
.langageStatus .storyStatus {
  padding: 2px;
  margin: 2px 2px 0 0;
  border: 1px solid transparent;
  display: inline-block;
}
a.storyStatus--online,
.storyStatus--online {
  color: #48960c;
}
a.storyStatus--online:visited, a.storyStatus--online:hover,
.storyStatus--online:visited,
.storyStatus--online:hover {
  color: #48960c;
}
a.storyStatus--scheduled,
.storyStatus--scheduled {
  color: #f57600;
}
a.storyStatus--scheduled:visited, a.storyStatus--scheduled:hover,
.storyStatus--scheduled:visited,
.storyStatus--scheduled:hover {
  color: #f57600;
}
a.storyStatus--to_republish,
.storyStatus--to_republish {
  color: #fac400;
}
a.storyStatus--to_republish:visited, a.storyStatus--to_republish:hover,
.storyStatus--to_republish:visited,
.storyStatus--to_republish:hover {
  color: #fac400;
}
a.storyStatus--offline,
.storyStatus--offline {
  color: #e62700;
}
a.storyStatus--offline:visited, a.storyStatus--offline:hover,
.storyStatus--offline:visited,
.storyStatus--offline:hover {
  color: #e62700;
}
a.storyStatus--edit,
.storyStatus--edit {
  color: #004a71;
}
a.storyStatus--edit:visited, a.storyStatus--edit:hover,
.storyStatus--edit:visited,
.storyStatus--edit:hover {
  color: #004a71;
}
a.storyStatus--new,
.storyStatus--new {
  color: #007cbb;
}
a.storyStatus--new:visited, a.storyStatus--new:hover,
.storyStatus--new:visited,
.storyStatus--new:hover {
  color: #007cbb;
}
a.storyStatus--ready_to_publish,
.storyStatus--ready_to_publish {
  color: #fac400;
}
a.storyStatus--ready_to_publish:visited, a.storyStatus--ready_to_publish:hover,
.storyStatus--ready_to_publish:visited,
.storyStatus--ready_to_publish:hover {
  color: #fac400;
}
a.storyStatus--in_translation,
.storyStatus--in_translation {
  color: #565656;
}
a.storyStatus--in_translation:visited, a.storyStatus--in_translation:hover,
.storyStatus--in_translation:visited,
.storyStatus--in_translation:hover {
  color: #565656;
}
a.storyStatus--translated,
.storyStatus--translated {
  color: #565656;
}
a.storyStatus--translated:visited, a.storyStatus--translated:hover,
.storyStatus--translated:visited,
.storyStatus--translated:hover {
  color: #565656;
}
a.storyStatus--video-ok,
.storyStatus--video-ok {
  border: 1px solid currentColor !important;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
a.storyStatus--selected,
.storyStatus--selected {
  text-decoration: underline;
}

.videoStatus.stories-status__tooltip-video.storyStatus--video-no {
  color: #e62700 !important;
  border: none !important;
  text-decoration: none !important;
}
.videoStatus.stories-status__tooltip-video.storyStatus--video-no::before {
  content: "no ";
  display: inline;
  font-weight: bold;
}
.videoStatus.stories-status__tooltip-video.storyStatus--video-ok {
  color: #48960c !important;
  border: 1px solid #565656 !important;
  text-decoration: none !important;
}
.videoStatus.stories-status__tooltip-video.storyStatus--video-ok::before {
  content: "";
  display: inline;
}

.eventLangage {
  padding: 2px 0;
}

::-webkit-input-placeholder {
  direction: ltr;
  text-align: left;
}

:-moz-placeholder {
  direction: ltr;
  text-align: left;
}

::-moz-placeholder {
  direction: ltr;
  text-align: left;
}

:-ms-input-placeholder {
  direction: ltr;
  text-align: left;
}

section > h1 {
  margin-top: 0;
}

a:visited {
  color: #003d79;
}

h1 > i {
  margin-right: 16px;
}

dt {
  margin: 1rem 0 0 0;
  font-weight: 900;
}

ul {
  margin: 0 0 0 0;
}

.c-link {
  cursor: pointer;
  text-decoration: none;
  color: #007cbb;
}
.c-link:visited {
  color: #003d79;
}
.c-link:hover, .c-link:focus {
  text-decoration: none;
}
.c-link--submit {
  background: transparent;
  border-radius: 4px;
  border: 1px solid #aaaaaa;
  color: #aaaaaa;
  cursor: pointer;
  font-size: 10px;
  line-height: 11px;
  margin-right: 8px;
  padding: 1px 3px;
}
.c-link--submit:hover {
  border-color: #565656;
  color: #565656;
}
.c-link--float {
  float: left;
}

.c-li {
  list-style-type: none;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-current-item {
  font-weight: bold;
}

.u-h2-like {
  color: #000000;
  font-size: 28px;
  letter-spacing: normal;
}

.u-txt-bold {
  font-weight: bold;
}

.u-txt-center {
  text-align: center;
}

.u-text--ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.u--ib {
  display: inline-block;
  text-align: center;
  font-style: italic;
}

.u-line-overflow {
  text-overflow: ellipsis;
  max-width: calc(100% - 30px);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}

.u-text--nowrap {
  white-space: nowrap;
}

.u-txt-right {
  text-align: right;
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u--hint {
  color: #80746d;
}

.u--subtext {
  color: #eeeeee;
  font-size: 0.9em;
}

.modal-backdrop {
  z-index: 619;
}

.modal-backdrop {
  z-index: 619;
}

.header {
  z-index: 504;
}

.datagrid .datagrid-column .datagrid-filter {
  z-index: 9 !important;
}

.u--level-1 {
  z-index: 1;
  margin: -20px auto auto -20px !important;
}

.u--level-2 {
  z-index: 2;
}

[style^="--aspect-ratio"]::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}