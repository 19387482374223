//  Display
// --------------------
.u-inline-block {
  display: inline-block;
}

.u-block {
  display: block;
}

.u-clear {
  clear: both;
}

.u-none {
  display: none;
}
