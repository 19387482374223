//  Forms
// --------------------

label span.required::after {
  content: "*";
  font-size: 1.1em;
  color: #c92100;
  margin: 0 0 0 0.2em;
}

label span.required {
  margin-top: 0;
  margin-bottom: 0;
  top: 0;
}

.locked .checkbox input[type="checkbox"]:checked + label::before {
  background: $medium-grey !important;
}

// Change autocomplete styles in Chrome
input,
select,
textarea {
  &:-webkit-autofill {
    &,
    &:focus,
    &:hover {
      -webkit-text-fill-color: $black;
      -webkit-box-shadow: 0 0 0 1000px $white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

/******************************/
/* Test for news Clarity form */
/******************************/

.clr-control-label {
  font-weight: 400;
  line-height: 1rem;
}

.clr-control-container.fullwidth {
  width: 100%;

  input,
  textarea {
    flex: 1;
    max-width: 100%;
    width: 100%;
  }
}

.clr-input-custom {
  height: auto;
  max-height: none;

  .clr-input-wrapper {
    max-height: none;
  }
}

.u-form-fullwidth .clr-form-control .clr-input-wrapper {
  max-width: none;
}
