//  Colors Settings
// --------------------

//  1. Colors Base
//  2. Status Colors
//  3. Themes Colors
//  4. Alerts Colors

//  1. Colors Base
// --------------------
$black: #000000;
$dark-grey: #1a1b1b;
$dark-grey-2: #313131;
$dark-grey-3: #333333;
$dark-grey-4: #444444;
$grey: #565656;
$grey-2: #7c7d7d;
$grey-3: #969696;
$medium-grey: #aaaaaa;
$light-grey: #cccccc;
$light-grey-2: #dddddd;
$super-light-grey: #eeeeee;
$super-light-grey-2: #fafafa;
$white: #ffffff;

$green: #48960c;
$green-dev-env: #85bc31;
$green-video-template: #082332;
$dark-green: #266900;
$light-blue: #6eabc0;
$cyan-blue: #00b7d6;
$author-blue: #2199e8;
$clarity-blue: #0072a3;
$blue-neon: #017ff8;
$blue-neon-flashy: #0172f0;
$blue-neon-flashy-light: #62adf6;
$blue-site: #1b9fce;
$blue-tag: #005587;
$blue: #007cbb;
$menu-blue: #1c7a9c;
$aside-menu: #006a91;
$medium-blue: #004a71;
$dark-blue: #003d79;
$purple: #781da0;
$pink: #e88c8c;
$light-red: #fff8f7;
$light-red-2: #f5dbd9;
$light-red-3: #fff2f0;
$dark-pink: #e06565;
$red: #e62700;
$red-2: #ed0e05;
$red-3: #c21d00;
$red-alert: #ef3900;
$orange-hot: #f57600;
$jaune-africa: #f9d713;
$jaune-fonce-africa: #e7b322;
$orange: #fac400;
$light-orange: #fff9eb;
$light-orange-2: #ffeec6;
$brown: #aa4500;
$darker-greenish-video-template-scrollbar-track: #002e44;
$lighter-greenish-video-template-scrollbar-track: #123e5b;
$warm-grey: #80746d;
$bluish-dark-grey: #414957;
$snowcap: #d9e4ea;

$input-border: #cbd5e1;

//  2. Status Colors
// --------------------
$color--online: $green;
$color--scheduled: $orange-hot;
$color--to_republish: $orange;
$color--ready_to_publish: $orange;
$color--offline: $red;
$color--edit: $medium-blue;
$color--new: $blue;
$color--in_duplication: $grey;
$color--duplicated: $grey;
$color--awaiting: $orange-hot;

$color--in_translation: $color--in_duplication;
$color--translated: $color--duplicated;
$color--submitted: $dark-blue;
$color--approved: $dark-green;

//  3. Pyramid Status Colors
// --------------------
$color--pth: $green;
$color--das: $orange-hot;
$color--wip: $orange;
$color--psb: $pink;
$color--pap: $red;
$color--loc: $medium-blue;
$color--aal: $blue;
$color--pal: $purple;
$color--cls: $grey;

// 4. Themes Colors
// --------------------
$euronews: #003865;
$africanews: #f9d713;
$travel: #ff855c;
$green-vertical: #31c87c;
$next-vertical: #ffb200;
$culture-vertical: #b0339a;

$ai-active: #7010fb;
$ai-hover: #f3e6ff;

//  5. Alerts Colors
// --------------------
$is-warning: #fac400;
$warning-color: #ffdc0b;
$warning-subcolor: #feecb5;

$is-warning-hot: #f57600;

$is-error: #e62700;
$danger-color: #ebafa6;
$danger-subcolor: #f5dbd9;

$is-info: #007cbb;
$info-color: #49afd9;
$info-subcolor: #e1f1f6;

$is-success: #2e9f05;
$success-color: #60b515;
$success-subcolor: #dff0d0;
$light-green: #a7cb8c;
