//  Euronews Dislay
// --------------------
.t-euronews {
  .show-for-euronews {
    display: block;
  }

  .show-for-africanews {
    display: none;
  }

  .hide-for-euronews {
    display: none !important;
  }
}

.u-background--euronews {
  background: $super-light-grey;
}
