//  Z-index
// --------------------
.modal-backdrop {
  z-index: z("modal")-1;
}

.modal-backdrop {
  z-index: z("modal") - 1;
}

// This allows our branded drop-shadow on the header bar to sit above the content
.header {
  z-index: 504;
}

//to override clarity
.datagrid .datagrid-column .datagrid-filter {
  z-index: z("upside") !important;
}

.u--level-1 {
  z-index: 1;
  margin: -20px auto auto -20px !important;
}

.u--level-2 {
  z-index: 2;
}
