//  Spacing utilities
// --------------------

//  1. Old margin utils
//  2. Old padding utils
//  3. News margin tools

//  1. Old margins utils
// --------------------
.u-align--center {
  margin: auto;
}

.u-ma-t-10p {
  margin-top: 10px;
}

.u-ma-t-16p {
  margin-top: 16px;
}

.u-ma-neg-x-10 {
  margin-left: -5px !important;
}

.u-ma-l-6p {
  margin-left: 6px;
}

.u-ma-l-12p {
  margin-left: 12px;
}

//  2. Old Paddings
// --------------------
.u-padding-right-12p {
  padding-right: 12px;
}

.c-padding-left-w50 {
  padding-left: 50%;
}

.u-pa-b-14p {
  padding-bottom: 12px;
}

// Frequently used padding and margin properties should be placed in this file. Every
// padding and margin declared is a multiplication of the `$global-base-unit`.
//
// If you wish to create custom spacing you can use the mixins
// `generate-margin($multiplier, $side)`, `generate-margin-end($multiplier)`, etc. found
// in `_tools.spacing.scss`
//
// ## Padding
//
// The following classes can be used to compose an element or extend a component:
//
// - .u-padding-*
// - .u-padding-top-*
// - .u-padding-right-*
// - .u-padding-bottom-*
// - .u-padding-left-*
// - .u-padding-start-* : Adds padding-left on LTR, padding-right on RTL
// - .u-padding-end-* : Adds padding-right on LTR, padding-left on RTL
// - .u-padding-y-* : Adds padding-top and bottom
// - .u-padding-x-* : Adds padding left and right
//
// You can also specify a breakpoint e.g. `u-padding-left-medium-3`, `u-padding-left-large-3`, etc.

//  Margin
@include generate-margin(0);
@include generate-margin(0, bottom);
@include generate-margin(2, bottom);
@include generate-margin(0, left);
@include generate-margin(1, left);
@include generate-margin(2, left);
@include generate-margin(0, right);
@include generate-margin(1, right);
@include generate-margin(2, right);
@include generate-margin(0, top);
@include generate-margin(2, top);
@include generate-margin(4, top);

@include generate-margin-x(0);
@include generate-margin-x(1);
@include generate-margin-x(2);
@include generate-margin-y(0);
@include generate-margin-y(3);

@include generate-margin-end(0);
@include generate-margin-start(0);

//  Padding
@include generate-padding(0);
@include generate-padding(3);
@include generate-padding(0, bottom);
@include generate-padding(2, bottom);
@include generate-padding(0, right);
@include generate-padding(2, right);
@include generate-padding(0, left);
@include generate-padding(0, top);
@include generate-padding(2, top);
@include generate-padding(7, bottom);

@include generate-padding-x(0);
@include generate-padding-x(3);
@include generate-padding-y(0);
@include generate-padding-y(3);

@include generate-padding-end(0);
@include generate-padding-start(1);

// No need to use a mixin here
.u-no-padding-x {
  padding-left: auto;
  padding-right: auto;
}

.u-margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
