//  Images
// --------------
.image__ratio {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.image__ratio > img,
.image__ratio > iframe,
.image__ratio > video,
.image__ratio > cms-image-preview,
.image__ratio > .no-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image__ratio--16x9 {
  padding: 56.25% 0 0 0;
}

.image__ratio--4x3 {
  padding: 75% 0 0 0;
}

.image__ratio--1x1 {
  padding: 100% 0 0 0;
}
