//  Forms
// --------------------
.u-form-fullwidth {
  width: 100%;

  .clr-control-container {
    width: 100%;
    max-width: 100%;
    flex: 1;
  }

  input,
  textarea {
    width: 100%;
  }
}

.required::after {
  color: red;
  content: "*";
}

// Make the radio labels not take the full length of the available space.
.clr-radio-wrapper {
  display: block;
}

.clr-radio-wrapper label {
  display: inline-block !important;
}

// Remove strange padding on the forms
.clr-form,
.clr-form-group {
  padding: 0.25rem 0 !important;
}
// Firefox only - remove black focus outline
.btn-group .btn input[type="radio"]:focus,
.btn-group .btn input[type="checkbox"]:focus {
  opacity: 0;
}

// Remove top margin from first form control
section > .clr-form-control {
  margin-top: 0;
}
section > .clr-form-control ~ .clr-form-control {
  margin-top: 1rem;
}

td .clr-form-control > .clr-control-label {
  width: auto !important;
}

// DBOT-444
.tag-container .clr-input-wrapper {
  max-width: 100%;
  max-height: none;
}

.clr-form-control.clr-row {
  margin-left: 0;
  margin-right: 0;
}
