.owl-dt-container {
  font-size: 0.6rem;
  .owl-dt-calendar {
    height: 21.25em;
  }
}

.owl-dt-calendar-table {
  font-weight: bold;
  font-size: 14px !important;
}

.owl-dt-timer {
  height: 8em;
}

/* We need to keep this overrides for compatibility with clarity */
.cdk-overlay-container {
  z-index: 1060;
}

.cdk-global-scrollblock {
  top: 0 !important;
  overflow-y: auto;
}

// for the filter style (we want a smaller calendar)
#filterDate .owl-dt-container {
  font-size: 0.5rem;
  line-height: 14px;
  box-shadow: 0 0 0 transparent;
}
