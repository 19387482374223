// Typography settings
// --------------------

@import "fonts/inter.scss";

//  1. Fonts Base
// --------------------
$font-xxsmall: 8px;
$font-xsmall: 10px;
$font-msmall: 11px;
$font-small: 12px;
$font-med-small: 13px;
$font-std: 14px;
$font-smedium: 16px;
$font-medium: 18px;
$font-xmedium: 20px;
$font-large: 24px;
$font-xlarge: 28px;
$font-xxlarge: 32px;

// 2. Font families
// --------------------
$font-family-inter: Inter, "Arial size-adjust", sans-serif;
