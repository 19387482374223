@use "sass:math";
//  Padding Settings
// --------------------

//  1. Base padding
// --------------------
$padding-xxsmall: math.div($base-unit, 2);
$padding-xsmall: $base-unit;
$padding-small: $base-unit * 2;
$padding-std: $base-unit * 4;
$padding-medium: $base-unit * 6;
$padding-large: $base-unit * 8;
