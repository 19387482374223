//  Pointers
// --------------------
.locked {
  cursor: not-allowed;
}

.u-no-event {
  pointer-events: none;
}

.c-pointer {
  cursor: pointer;
}

.u-select-none {
  user-select: none;
}
