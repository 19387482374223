//  Custom Filters
// --------------------

.datagrid-filter {
  .c-custom-filter {
    &__header {
      margin-bottom: 20px;
      font-weight: bold;

      &-title {
        font-size: $font-small;
        padding-top: 2px;
      }
    }

    &__footer {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid $light-grey;
      text-align: right;

      .btn {
        margin: 0;
      }

      .btn-outline {
        margin-right: 12px;
      }
    }
  }

  .datagrid-filter-close-wrapper {
    display: none;
  }
}
