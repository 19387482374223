//  1. Gutters utilities
// --------------------

//  1. No Gutters utilities
//  2. Gutters utilities

//  1. No Gutters utilities
// --------------------
.u-no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-no-gutter-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-no-gutter-left {
  padding-left: 0 !important;
}

.u--no-gutter-left-right {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
//  2.  Gutters utilities
// --------------------
.u-gutter-24 {
  padding: 24px;
}
