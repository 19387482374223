//  Flex utilities
// --------------------
.u-flex {
  display: flex;
}

.u-cell-flex {
  display: flex;

  > * {
    margin: 0 $base-unit auto 0;
  }

  img {
    margin-top: -4px;
    min-width: 20px;
    height: 20px;
  }
}

.u-column {
  flex-direction: column;
}

.u-row {
  flex-direction: row;
}

.u-flex--1 {
  flex: 1 0 auto !important;
}

.u-flex--spacebetween {
  justify-content: space-between;
}
