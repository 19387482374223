//  Shadows
// --------------------
.u-inner-shadow {
  box-shadow: 5px -2px 10px rgba(0, 0, 0, 0.15) inset;
}

.u-outer-shadow {
  box-shadow: 12px 0 12px rgba(0, 0, 0, 0.2);
}

.u-outer-shadow--soft {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

[dir="rtl"] .u-inner-shadow {
  box-shadow: none;
}
