//  Utils Mixins
// --------------------

//  1. Direction RTL
//  3. Strip Units
//  4. Multi-lines Clean Crop
//  5. Rem-Calc
//  6. Text-Overflow
//  7. SVG play-button

//  1. Direction RTL
// --------------------
@use "sass:math";

@mixin direction-rtl() {
  &[dir="rtl"] {
    @content;
  }
}

//  2. Strip Units
// --------------------
@function strip-units($number) {
  @return math.div($number, $number * 0 + 1);
}

//  3. Multi-lines Clean Crop
// --------------------
@mixin multiLineCleanCrop($lineHeight: 1.2, $lineCount: 1) {
  line-height: $lineHeight;
  max-height: strip-units($lineHeight * $lineCount) + rem;
  overflow: hidden;
}

//  4. Rem-Calc
// --------------------
@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base ==null {
    $base: $global-font-size;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == "%" {
    $base: math.div($base, 100%) * 16px;
  }

  @if $count ==1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

//  5. Text-Overflow
// --------------------
@mixin text-overflow() {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

//  6. Text-Overflow
// --------------------
@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

//  7. SVG play-button
// --------------------
@mixin play-btn($svgUrl, $percentage, $background-position-x: null, $background-position-y: null) {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: z("default");
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url($svgUrl) no-repeat center;
    background-position: $background-position-x $background-position-y;
    transform: scale(2.4);
  }
}

// Mixin pour une grille adaptable
@mixin responsive-grid($columns) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: 16px; // Espacement par défaut entre les éléments
}
